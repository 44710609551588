// Generated by purs version 0.15.15
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_List from "../Data.List/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Parsing from "../Parsing/index.js";
import * as Parsing_Combinators from "../Parsing.Combinators/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Parsing.bindParserT);
var many = /* #__PURE__ */ Data_List.many(Parsing.alternativeParserT)(Parsing.lazyParserT);
var applySecond = /* #__PURE__ */ Control_Apply.applySecond(Parsing.applyParserT);
var pure = /* #__PURE__ */ Control_Applicative.pure(Parsing.applicativeParserT);
var sepByLazy = function (dictMonad) {
    return function (elemp) {
        return function (sepp) {
            return bind(elemp)(function (h) {
                return bind(many(Parsing_Combinators["try"](applySecond(sepp)(elemp))))(function (t) {
                    return pure(new Data_List_Types.Cons(h, t));
                });
            });
        };
    };
};
export {
    sepByLazy
};
