// Generated by purs version 0.15.15
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_HTML_Properties from "../Halogen.HTML.Properties/index.js";
var overlay = function (panel_classes) {
    return function (contents) {
        return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("overlay") ])([ Halogen_HTML_Elements.div([ Halogen_HTML_Properties.classes(panel_classes) ])([ Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("overlay_content") ])(contents) ]) ]);
    };
};
export {
    overlay
};
