// Generated by purs version 0.15.15
var mouseup = "mouseup";
var mouseover = "mouseover";
var mouseout = "mouseout";
var mousemove = "mousemove";
var mouseleave = "mouseleave";
var mouseenter = "mouseenter";
var mousedown = "mousedown";
var dblclick = "dblclick";
var click = "click";
var auxclick = "auxclick";
export {
    auxclick,
    click,
    dblclick,
    mousedown,
    mouseenter,
    mouseleave,
    mousemove,
    mouseout,
    mouseover,
    mouseup
};
