// Generated by purs version 0.15.15
import * as Control_Comonad_Cofree from "../Control.Comonad.Cofree/index.js";
import * as Control_Comonad_Env_Trans from "../Control.Comonad.Env.Trans/index.js";
import * as Control_Monad_Free from "../Control.Monad.Free/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor_Mu from "../Data.Functor.Mu/index.js";
import * as Data_Functor_Nu from "../Data.Functor.Nu/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Matryoshka_Pattern_CoEnvT from "../Matryoshka.Pattern.CoEnvT/index.js";
var recursiveNu = function (dictFunctor) {
    return {
        project: Data_Functor_Nu.observe(dictFunctor),
        Functor0: function () {
            return dictFunctor;
        }
    };
};
var recursiveMu = function (dictFunctor) {
    return {
        project: Data_Functor_Mu.unroll,
        Functor0: function () {
            return dictFunctor;
        }
    };
};
var recursiveFree = function (dictFunctor) {
    var functorEnvT = Matryoshka_Pattern_CoEnvT.functorEnvT(dictFunctor);
    return {
        project: (function () {
            var $10 = Data_Either.either(Data_Either.Right.create)(Data_Either.Left.create);
            var $11 = Control_Monad_Free.resume(dictFunctor);
            return function ($12) {
                return Matryoshka_Pattern_CoEnvT.CoEnvT($10($11($12)));
            };
        })(),
        Functor0: function () {
            return functorEnvT;
        }
    };
};
var recursiveCofree = function (dictFunctor) {
    var functorEnvT = Control_Comonad_Env_Trans.functorEnvT(dictFunctor);
    return {
        project: function (cf) {
            return new Data_Tuple.Tuple(Control_Comonad_Cofree.head(cf), Control_Comonad_Cofree.tail(cf));
        },
        Functor0: function () {
            return functorEnvT;
        }
    };
};
var project = function (dict) {
    return dict.project;
};
export {
    project,
    recursiveMu,
    recursiveNu,
    recursiveFree,
    recursiveCofree
};
