// Generated by purs version 0.15.15
var sized = function (dict) {
    return dict.sized;
};
var resize = function (dict) {
    return dict.resize;
};
var chooseInt = function (dict) {
    return dict.chooseInt;
};
var chooseFloat = function (dict) {
    return dict.chooseFloat;
};
var chooseBool = function (dict) {
    return dict.chooseBool;
};
export {
    chooseBool,
    chooseFloat,
    chooseInt,
    resize,
    sized
};
