// Generated by purs version 0.15.15
import * as $foreign from "./foreign.js";

export {
    mkSTFn1,
    mkSTFn2,
    mkSTFn3,
    mkSTFn4,
    mkSTFn5,
    mkSTFn6,
    mkSTFn7,
    mkSTFn8,
    mkSTFn9,
    mkSTFn10,
    runSTFn1,
    runSTFn2,
    runSTFn3,
    runSTFn4,
    runSTFn5,
    runSTFn6,
    runSTFn7,
    runSTFn8,
    runSTFn9,
    runSTFn10
} from "./foreign.js";
