// Generated by purs version 0.15.15
var focusout = "focusout";
var focusin = "focusin";
var focus = "focus";
var blur = "blur";
export {
    blur,
    focus,
    focusin,
    focusout
};
