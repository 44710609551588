// Generated by purs version 0.15.15
import * as Data_Bifunctor from "../Data.Bifunctor/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
var lmap = /* #__PURE__ */ Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither);
var map = /* #__PURE__ */ Data_Functor.map(Data_Either.functorEither);
var CoEnvT = function (x) {
    return x;
};
var withEnvT = function (f) {
    return function (v) {
        return lmap(f)(v);
    };
};
var runEnvT = function (v) {
    return v;
};
var newtypeEnvT = {
    Coercible0: function () {
        return undefined;
    }
};
var mapEnvT = function (f) {
    return function (v) {
        return map(f)(v);
    };
};
var functorEnvT = function (dictFunctor) {
    var map1 = Data_Functor.map(dictFunctor);
    return {
        map: function (f) {
            return function (v) {
                return map(map1(f))(v);
            };
        }
    };
};
export {
    CoEnvT,
    runEnvT,
    withEnvT,
    mapEnvT,
    newtypeEnvT,
    functorEnvT
};
