// Generated by purs version 0.15.15
import * as Control_Comonad_Store from "../Control.Comonad.Store/index.js";
import * as Data_Exists from "../Data.Exists/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
var NuF = function (x) {
    return x;
};
var Nu = function (x) {
    return x;
};
var unfold = function (pos) {
    return function (peek) {
        return Data_Exists.mkExists(Control_Comonad_Store.store(peek)(pos));
    };
};
var observeF = function (dictFunctor) {
    var map = Data_Functor.map(dictFunctor);
    return function (v) {
        var v1 = Control_Comonad_Store.runStore(v);
        return map(Data_Function.flip(unfold)(v1.value0))(v1.value0(v1.value1));
    };
};
var observe = function (dictFunctor) {
    var observeF1 = observeF(dictFunctor);
    return function (v) {
        return Data_Exists.runExists(observeF1)(v);
    };
};
var newtypeNu = {
    Coercible0: function () {
        return undefined;
    }
};
export {
    Nu,
    NuF,
    unfold,
    observe,
    newtypeNu
};
