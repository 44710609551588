// Generated by purs version 0.15.15
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Alternative from "../Control.Alternative/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Control_Lazy from "../Control.Lazy/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Boolean from "../Data.Boolean/index.js";
import * as Data_Char from "../Data.Char/index.js";
import * as Data_CodePoint_Unicode from "../Data.CodePoint.Unicode/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Int from "../Data.Int/index.js";
import * as Data_List from "../Data.List/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Number from "../Data.Number/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Ordering from "../Data.Ordering/index.js";
import * as Data_Ring from "../Data.Ring/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_String_CodePoints from "../Data.String.CodePoints/index.js";
import * as Data_String_CodeUnits from "../Data.String.CodeUnits/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Data_String_Unicode from "../Data.String.Unicode/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Data_Unfoldable from "../Data.Unfoldable/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Parsing from "../Parsing/index.js";
import * as Parsing_Combinators from "../Parsing.Combinators/index.js";
import * as Parsing_String from "../Parsing.String/index.js";
import * as Parsing_String_Basic from "../Parsing.String.Basic/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Parsing.bindParserT);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Parsing.bindParserT);
var pure = /* #__PURE__ */ Control_Applicative.pure(Parsing.applicativeParserT);
var guard = /* #__PURE__ */ Control_Alternative.guard(Parsing.alternativeParserT);
var sort = /* #__PURE__ */ Data_Array.sort(Data_Ord.ordString);
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var applySecond = /* #__PURE__ */ Control_Apply.applySecond(Parsing.applyParserT);
var compare = /* #__PURE__ */ Data_Ord.compare(Data_Ord.ordString);
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var fix = /* #__PURE__ */ Control_Lazy.fix(Parsing.lazyParserT);
var alt = /* #__PURE__ */ Control_Alt.alt(Parsing.altParserT);
var $$void = /* #__PURE__ */ Data_Functor["void"](Parsing.functorParserT);
var voidLeft = /* #__PURE__ */ Data_Functor.voidLeft(Parsing.functorParserT);
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var many = /* #__PURE__ */ Data_Array.many(Parsing.alternativeParserT)(Parsing.lazyParserT);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var some = /* #__PURE__ */ Data_Array.some(Parsing.alternativeParserT)(Parsing.lazyParserT);
var foldl = /* #__PURE__ */ Data_Foldable.foldl(Data_Foldable.foldableArray);
var applyFirst = /* #__PURE__ */ Control_Apply.applyFirst(Parsing.applyParserT);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showString);
var bind1 = /* #__PURE__ */ Control_Bind.bind(Data_Maybe.bindMaybe);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Data_Maybe.applicativeMaybe);
var foldr = /* #__PURE__ */ Data_Foldable.foldr(Data_Foldable.foldableArray);
var map2 = /* #__PURE__ */ Data_Functor.map(Parsing.functorParserT);
var choice = /* #__PURE__ */ Parsing_Combinators.choice(Data_Foldable.foldableArray);
var many1 = /* #__PURE__ */ Data_List.many(Parsing.alternativeParserT)(Parsing.lazyParserT);
var toUnfoldable = /* #__PURE__ */ Data_List.toUnfoldable(Data_Unfoldable.unfoldableArray);
var foldr1 = /* #__PURE__ */ Data_Foldable.foldr(Data_List_Types.foldableList);
var LanguageDef = function (x) {
    return x;
};
var unGenLanguageDef = function (v) {
    return v;
};
var token = function (tokpos) {
    return bind(Parsing.getParserT)(function (v) {
        var v1 = Data_List.uncons(v.value0);
        if (v1 instanceof Data_Maybe.Nothing) {
            return Parsing.fail("Unexpected EOF");
        };
        if (v1 instanceof Data_Maybe.Just) {
            return discard(Parsing.stateParserT(function (v2) {
                return new Data_Tuple.Tuple(Data_Unit.unit, new Parsing.ParseState(v1.value0.tail, tokpos(v1.value0.head), true));
            }))(function () {
                return pure(v1.value0.head);
            });
        };
        throw new Error("Failed pattern match at Parsing.Token (line 55, column 3 - line 59, column 16): " + [ v1.constructor.name ]);
    });
};
var when = function (tokpos) {
    return function (f) {
        return Parsing_Combinators.tryRethrow(bind(token(tokpos))(function (a) {
            return discard(guard(f(a)))(function () {
                return pure(a);
            });
        }));
    };
};
var theReservedNames = function (v) {
    if (v.caseSensitive) {
        return sort(v.reservedNames);
    };
    if (Data_Boolean.otherwise) {
        return sort(map(Data_String_Common.toLower)(v.reservedNames));
    };
    throw new Error("Failed pattern match at Parsing.Token (line 825, column 1 - line 825, column 70): " + [ v.constructor.name ]);
};
var simpleSpace = /* #__PURE__ */ Parsing_Combinators.skipMany1(/* #__PURE__ */ Parsing_String.satisfyCodePoint(Data_CodePoint_Unicode.isSpace));
var oneLineComment = function (v) {
    return applySecond(Parsing_Combinators["try"](Parsing_String.string(v.commentLine)))(Parsing_Combinators.skipMany(Parsing_String.satisfy(function (v1) {
        return v1 !== "\x0a";
    })));
};
var match = function (dictEq) {
    var eq = Data_Eq.eq(dictEq);
    return function (tokpos) {
        return function (tok) {
            return when(tokpos)(function (v) {
                return eq(v)(tok);
            });
        };
    };
};
var isReserved = function ($copy_names) {
    return function ($copy_name) {
        var $tco_var_names = $copy_names;
        var $tco_done = false;
        var $tco_result;
        function $tco_loop(names, name) {
            var v = Data_Array.uncons(names);
            if (v instanceof Data_Maybe.Nothing) {
                $tco_done = true;
                return false;
            };
            if (v instanceof Data_Maybe.Just) {
                var v1 = compare(v.value0.head)(name);
                if (v1 instanceof Data_Ordering.LT) {
                    $tco_var_names = v.value0.tail;
                    $copy_name = name;
                    return;
                };
                if (v1 instanceof Data_Ordering.EQ) {
                    $tco_done = true;
                    return true;
                };
                if (v1 instanceof Data_Ordering.GT) {
                    $tco_done = true;
                    return false;
                };
                throw new Error("Failed pattern match at Parsing.Token (line 820, column 35 - line 823, column 18): " + [ v1.constructor.name ]);
            };
            throw new Error("Failed pattern match at Parsing.Token (line 818, column 3 - line 823, column 18): " + [ v.constructor.name ]);
        };
        while (!$tco_done) {
            $tco_result = $tco_loop($tco_var_names, $copy_name);
        };
        return $tco_result;
    };
};
var isReservedName = function (v) {
    return function (name) {
        var caseName = (function () {
            if (v.caseSensitive) {
                return name;
            };
            if (Data_Boolean.otherwise) {
                return Data_String_Common.toLower(name);
            };
            throw new Error("Failed pattern match at Parsing.Token (line 812, column 3 - line 814, column 31): " + [  ]);
        })();
        return isReserved(theReservedNames(v))(caseName);
    };
};
var inCommentSingle = function (v) {
    var startEnd = append(Data_String_CodeUnits.toCharArray(v.commentEnd))(Data_String_CodeUnits.toCharArray(v.commentStart));
    return fix(function (p) {
        return alt($$void(Parsing_Combinators["try"](Parsing_String.string(v.commentEnd))))(alt(applySecond(Parsing_Combinators.skipMany1(Parsing_String_Basic.noneOf(startEnd)))(p))(Parsing_Combinators.withErrorMessage(applySecond(Parsing_String_Basic.oneOf(startEnd))(p))("end of comment")));
    });
};
var multiLineComment = function (v) {
    return applySecond(Parsing_Combinators["try"](Parsing_String.string(v.commentStart)))(inComment(v));
};
var inCommentMulti = function (v) {
    var startEnd = append(Data_String_CodeUnits.toCharArray(v.commentEnd))(Data_String_CodeUnits.toCharArray(v.commentStart));
    return fix(function (p) {
        return alt($$void(Parsing_Combinators["try"](Parsing_String.string(v.commentEnd))))(alt(applySecond(multiLineComment(v))(p))(alt(applySecond(Parsing_Combinators.skipMany1(Parsing_String_Basic.noneOf(startEnd)))(p))(Parsing_Combinators.withErrorMessage(applySecond(Parsing_String_Basic.oneOf(startEnd))(p))("end of comment"))));
    });
};
var inComment = function (v) {
    if (v.nestedComments) {
        return inCommentMulti(v);
    };
    return inCommentSingle(v);
};
var whiteSpace$prime = function (v) {
    if (Data_String_Common["null"](v.commentLine) && Data_String_Common["null"](v.commentStart)) {
        return Parsing_Combinators.skipMany(Parsing_Combinators.withErrorMessage(simpleSpace)(""));
    };
    if (Data_String_Common["null"](v.commentLine)) {
        return Parsing_Combinators.skipMany(alt(simpleSpace)(Parsing_Combinators.withErrorMessage(multiLineComment(v))("")));
    };
    if (Data_String_Common["null"](v.commentStart)) {
        return Parsing_Combinators.skipMany(alt(simpleSpace)(Parsing_Combinators.withErrorMessage(oneLineComment(v))("")));
    };
    if (Data_Boolean.otherwise) {
        return Parsing_Combinators.skipMany(alt(simpleSpace)(alt(oneLineComment(v))(Parsing_Combinators.withErrorMessage(multiLineComment(v))(""))));
    };
    throw new Error("Failed pattern match at Parsing.Token (line 834, column 1 - line 834, column 74): " + [ v.constructor.name ]);
};
var makeTokenParser = function (v) {
    var stringLetter = Parsing_String.satisfy(function (c) {
        return c !== "\"" && (c !== "\\" && c > "\x1a");
    });
    var sign = function (dictRing) {
        return alt(voidLeft(Parsing_String["char"]("-"))(Data_Ring.negate(dictRing)))(alt(voidLeft(Parsing_String["char"]("+"))(identity))(pure(identity)));
    };
    var sign1 = sign(Data_Ring.ringInt);
    var oper = (function () {
        var go = bind(v.opStart)(function (c) {
            return bind(many(v.opLetter))(function (cs) {
                return pure(Data_String_CodeUnits.singleton(c) + Data_String_CodeUnits.fromCharArray(cs));
            });
        });
        return Parsing_Combinators.withErrorMessage(go)("operator");
    })();
    var number = function (base) {
        return function (baseDigit) {
            var folder = function (v1) {
                return function (v2) {
                    if (v1 instanceof Data_Maybe.Nothing) {
                        return Data_Maybe.Nothing.value;
                    };
                    if (v1 instanceof Data_Maybe.Just) {
                        return map1(function (v3) {
                            return (base * v1.value0 | 0) + v3 | 0;
                        })(Data_CodePoint_Unicode.hexDigitToInt(Data_String_CodePoints.codePointFromChar(v2)));
                    };
                    throw new Error("Failed pattern match at Parsing.Token (line 704, column 5 - line 704, column 45): " + [ v1.constructor.name, v2.constructor.name ]);
                };
            };
            return bind(some(baseDigit))(function (digits) {
                return Data_Maybe.maybe(Parsing.fail("not digits"))(pure)(foldl(folder)(new Data_Maybe.Just(0))(digits));
            });
        };
    };
    var octal = applySecond(Parsing_String_Basic.oneOf([ "o", "O" ]))(number(8)(Parsing_String_Basic.octDigit));
    var lexeme = function (p) {
        return applyFirst(p)(whiteSpace$prime(v));
    };
    var reservedOp = function (name) {
        var go = bind(Parsing_String.string(name))(function () {
            return Parsing_Combinators.withErrorMessage(Parsing_Combinators.notFollowedBy(v.opLetter))("end of " + name);
        });
        return lexeme(Parsing_Combinators["try"](go));
    };
    var symbol = function (name) {
        return voidLeft(lexeme(Parsing_String.string(name)))(name);
    };
    var parens = function (p) {
        return Parsing_Combinators.between(symbol("("))(symbol(")"))(p);
    };
    var semi = symbol(";");
    var semiSep = function (p) {
        return Parsing_Combinators.sepBy(p)(semi);
    };
    var semiSep1 = function (p) {
        return Parsing_Combinators.sepBy1(p)(semi);
    };
    var isReservedOp = function (name) {
        return isReserved(sort(v.reservedOpNames))(name);
    };
    var operator = (function () {
        var go = bind(oper)(function (name) {
            var $114 = isReservedOp(name);
            if ($114) {
                return Parsing.fail("reserved operator " + name);
            };
            return pure(name);
        });
        return lexeme(Parsing_Combinators["try"](go));
    })();
    var ident = (function () {
        var go = bind(v.identStart)(function (c) {
            return bind(many(v.identLetter))(function (cs) {
                return pure(Data_String_CodeUnits.singleton(c) + Data_String_CodeUnits.fromCharArray(cs));
            });
        });
        return Parsing_Combinators.withErrorMessage(go)("identifier");
    })();
    var identifier = (function () {
        var go = bind(ident)(function (name) {
            var $115 = isReservedName(v)(name);
            if ($115) {
                return Parsing.fail("reserved word " + show(name));
            };
            return pure(name);
        });
        return lexeme(Parsing_Combinators["try"](go));
    })();
    var hexadecimal = applySecond(Parsing_String_Basic.oneOf([ "x", "X" ]))(number(16)(Parsing_String_Basic.hexDigit));
    var fraction = (function () {
        var op = function (v1) {
            return function (v2) {
                if (v2 instanceof Data_Maybe.Nothing) {
                    return Data_Maybe.Nothing.value;
                };
                if (v2 instanceof Data_Maybe.Just) {
                    return bind1(Data_CodePoint_Unicode.hexDigitToInt(Data_String_CodePoints.codePointFromChar(v1)))(function (int$prime) {
                        return pure1((v2.value0 + Data_Int.toNumber(int$prime)) / 10.0);
                    });
                };
                throw new Error("Failed pattern match at Parsing.Token (line 651, column 5 - line 651, column 47): " + [ v1.constructor.name, v2.constructor.name ]);
            };
        };
        return Parsing_Combinators.asErrorMessage("fraction")(bind(Parsing_String["char"]("."))(function () {
            return bind(Parsing_Combinators.withErrorMessage(some(Parsing_String_Basic.digit))("fraction"))(function (digits) {
                return Data_Maybe.maybe(Parsing.fail("not digit"))(pure)(foldr(op)(new Data_Maybe.Just(0.0))(digits));
            });
        }));
    })();
    var escapeGap = Parsing_Combinators.withErrorMessage(applySecond(some(Parsing_String_Basic.space))(Parsing_String["char"]("\\")))("end of string gap");
    var escapeEmpty = Parsing_String["char"]("&");
    var escMap = Data_Array.zip([ "a", "b", "f", "n", "r", "t", "v", "\\", "\"", "'" ])([ "\x07", "\x08", "\x0c", "\x0a", "\x0d", "\x09", "\x0b", "\\", "\"", "'" ]);
    var dot = symbol(".");
    var decimal = number(10)(Parsing_String_Basic.digit);
    var exponent$prime = (function () {
        var power = function (e) {
            if (e < 0) {
                return 1.0 / power(-e | 0);
            };
            if (Data_Boolean.otherwise) {
                return Data_Number.pow(10.0)(Data_Int.toNumber(e));
            };
            throw new Error("Failed pattern match at Parsing.Token (line 664, column 5 - line 664, column 27): " + [ e.constructor.name ]);
        };
        return Parsing_Combinators.asErrorMessage("exponent")(bind(Parsing_String_Basic.oneOf([ "e", "E" ]))(function () {
            return bind(sign1)(function (f) {
                return bind(Parsing_Combinators.withErrorMessage(decimal)("exponent"))(function (e) {
                    return pure(power(f(e)));
                });
            });
        }));
    })();
    var fractExponent = function (n) {
        var justExponent = bind(exponent$prime)(function (expo) {
            return pure(Data_Int.toNumber(n) * expo);
        });
        var fractExponent$prime = bind(fraction)(function (fract) {
            return bind(Parsing_Combinators.option(1.0)(exponent$prime))(function (expo) {
                return pure((Data_Int.toNumber(n) + fract) * expo);
            });
        });
        return alt(fractExponent$prime)(justExponent);
    };
    var fractFloat = function (n) {
        return map2(Data_Either.Right.create)(fractExponent(n));
    };
    var decimalFloat = bind(decimal)(function (n) {
        return Parsing_Combinators.option(new Data_Either.Left(n))(fractFloat(n));
    });
    var zeroNumFloat = alt(map2(Data_Either.Left.create)(alt(hexadecimal)(octal)))(alt(decimalFloat)(alt(fractFloat(0))(pure(new Data_Either.Left(0)))));
    var natFloat = alt(applySecond(Parsing_String["char"]("0"))(zeroNumFloat))(decimalFloat);
    var naturalOrFloat = Parsing_Combinators.withErrorMessage(lexeme(natFloat))("number");
    var floating = bind(decimal)(fractExponent);
    var $$float = Parsing_Combinators.withErrorMessage(lexeme(floating))("float");
    var zeroNumber = Parsing_Combinators.withErrorMessage(applySecond(Parsing_String["char"]("0"))(alt(hexadecimal)(alt(octal)(alt(decimal)(pure(0))))))("");
    var nat = alt(zeroNumber)(decimal);
    var $$int = bind(lexeme(sign1))(function (f) {
        return bind(nat)(function (n) {
            return pure(f(n));
        });
    });
    var integer = Parsing_Combinators.withErrorMessage(lexeme($$int))("integer");
    var natural = Parsing_Combinators.withErrorMessage(lexeme(nat))("natural");
    var comma = symbol(",");
    var commaSep = function (p) {
        return Parsing_Combinators.sepBy(p)(comma);
    };
    var commaSep1 = function (p) {
        return Parsing_Combinators.sepBy1(p)(comma);
    };
    var colon = symbol(":");
    var charNum = bind(alt(decimal)(alt(applySecond(Parsing_String["char"]("o"))(number(8)(Parsing_String_Basic.octDigit)))(applySecond(Parsing_String["char"]("x"))(number(16)(Parsing_String_Basic.hexDigit)))))(function (code) {
        var $120 = code > 1114111;
        if ($120) {
            return Parsing.fail("invalid escape sequence");
        };
        var v1 = Data_Char.fromCharCode(code);
        if (v1 instanceof Data_Maybe.Just) {
            return pure(v1.value0);
        };
        if (v1 instanceof Data_Maybe.Nothing) {
            return Parsing.fail("invalid character code (should not happen)");
        };
        throw new Error("Failed pattern match at Parsing.Token (line 498, column 10 - line 500, column 67): " + [ v1.constructor.name ]);
    });
    var charLetter = Parsing_String.satisfy(function (c) {
        return c !== "'" && (c !== "\\" && c > "\x1a");
    });
    var charEsc = (function () {
        var parseEsc = function (v1) {
            return voidLeft(Parsing_String["char"](v1.value0))(v1.value1);
        };
        return choice(map(parseEsc)(escMap));
    })();
    var charControl = bind(Parsing_String["char"]("^"))(function () {
        return bind(Parsing_String_Basic.upper)(function (code) {
            var v1 = Data_Char.fromCharCode((Data_Char.toCharCode(code) - Data_Char.toCharCode("A") | 0) + 1 | 0);
            if (v1 instanceof Data_Maybe.Just) {
                return pure(v1.value0);
            };
            if (v1 instanceof Data_Maybe.Nothing) {
                return Parsing.fail("invalid character code (should not happen)");
            };
            throw new Error("Failed pattern match at Parsing.Token (line 488, column 5 - line 490, column 67): " + [ v1.constructor.name ]);
        });
    });
    var caseString = function (name) {
        if (v.caseSensitive) {
            return voidLeft(Parsing_String.string(name))(name);
        };
        if (Data_Boolean.otherwise) {
            var msg = show(name);
            var caseChar = function (c) {
                var v1 = function (v2) {
                    if (Data_Boolean.otherwise) {
                        return Parsing_String["char"](c);
                    };
                    throw new Error("Failed pattern match at Parsing.Token (line 355, column 1 - line 355, column 80): " + [ c.constructor.name ]);
                };
                var $131 = Data_CodePoint_Unicode.isAlpha(Data_String_CodePoints.codePointFromChar(c));
                if ($131) {
                    var $132 = Data_String_CodeUnits.toChar(Data_String_Unicode.toLowerSimple(Data_String_CodeUnits.singleton(c)));
                    if ($132 instanceof Data_Maybe.Just) {
                        var $133 = Data_String_CodeUnits.toChar(Data_String_Unicode.toUpperSimple(Data_String_CodeUnits.singleton(c)));
                        if ($133 instanceof Data_Maybe.Just) {
                            return alt(Parsing_String["char"]($132.value0))(Parsing_String["char"]($133.value0));
                        };
                        return v1(true);
                    };
                    return v1(true);
                };
                return v1(true);
            };
            var walk = function (name$prime) {
                var v1 = Data_String_CodeUnits.uncons(name$prime);
                if (v1 instanceof Data_Maybe.Nothing) {
                    return pure(Data_Unit.unit);
                };
                if (v1 instanceof Data_Maybe.Just) {
                    return applySecond(Parsing_Combinators.withErrorMessage(caseChar(v1.value0.head))(msg))(walk(v1.value0.tail));
                };
                throw new Error("Failed pattern match at Parsing.Token (line 757, column 22 - line 759, column 72): " + [ v1.constructor.name ]);
            };
            return voidLeft(walk(name))(name);
        };
        throw new Error("Failed pattern match at Parsing.Token (line 751, column 3 - line 751, column 50): " + [ name.constructor.name ]);
    };
    var reserved = function (name) {
        var go = applySecond(caseString(name))(Parsing_Combinators.withErrorMessage(Parsing_Combinators.notFollowedBy(v.identLetter))("end of " + name));
        return lexeme(Parsing_Combinators["try"](go));
    };
    var brackets = function (p) {
        return Parsing_Combinators.between(symbol("["))(symbol("]"))(p);
    };
    var braces = function (p) {
        return Parsing_Combinators.between(symbol("{"))(symbol("}"))(p);
    };
    var ascii3codes = [ "NUL", "SOH", "STX", "ETX", "EOT", "ENQ", "ACK", "BEL", "DLE", "DC1", "DC2", "DC3", "DC4", "NAK", "SYN", "ETB", "CAN", "SUB", "ESC", "DEL" ];
    var ascii3 = [ "\x00", "\x01", "\x02", "\x03", "\x04", "\x05", "\x06", "\x07", "\x10", "\x11", "\x12", "\x13", "\x14", "\x15", "\x16", "\x17", "\x18", "\x1a", "\x1b", "\x7f" ];
    var ascii2codes = [ "BS", "HT", "LF", "VT", "FF", "CR", "SO", "SI", "EM", "FS", "GS", "RS", "US", "SP" ];
    var ascii2 = [ "\x08", "\x09", "\x0a", "\x0b", "\x0c", "\x0d", "\x0e", "\x0f", "\x19", "\x1c", "\x1d", "\x1e", "\x1f", " " ];
    var asciiMap = Data_Array.zip(append(ascii3codes)(ascii2codes))(append(ascii3)(ascii2));
    var charAscii = (function () {
        var parseAscii = function (v1) {
            return Parsing_Combinators["try"](voidLeft(Parsing_String.string(v1.value0))(v1.value1));
        };
        return choice(map(parseAscii)(asciiMap));
    })();
    var escapeCode = alt(charEsc)(alt(charNum)(alt(charAscii)(Parsing_Combinators.withErrorMessage(charControl)("escape code"))));
    var charEscape = applySecond(Parsing_String["char"]("\\"))(escapeCode);
    var characterChar = alt(charLetter)(Parsing_Combinators.withErrorMessage(charEscape)("literal character"));
    var charLiteral = (function () {
        var go = Parsing_Combinators.between(Parsing_String["char"]("'"))(Parsing_Combinators.withErrorMessage(Parsing_String["char"]("'"))("end of character"))(characterChar);
        return Parsing_Combinators.withErrorMessage(lexeme(go))("character");
    })();
    var stringEscape = bind(Parsing_String["char"]("\\"))(function () {
        return alt(voidLeft(escapeGap)(Data_Maybe.Nothing.value))(alt(voidLeft(escapeEmpty)(Data_Maybe.Nothing.value))(map2(Data_Maybe.Just.create)(escapeCode)));
    });
    var stringChar = alt(map2(Data_Maybe.Just.create)(stringLetter))(Parsing_Combinators.withErrorMessage(stringEscape)("string character"));
    var stringLiteral = (function () {
        var folder = function (v1) {
            return function (v2) {
                if (v1 instanceof Data_Maybe.Nothing) {
                    return v2;
                };
                if (v1 instanceof Data_Maybe.Just) {
                    return new Data_List_Types.Cons(v1.value0, v2);
                };
                throw new Error("Failed pattern match at Parsing.Token (line 455, column 5 - line 455, column 51): " + [ v1.constructor.name, v2.constructor.name ]);
            };
        };
        var go = bind(Parsing_Combinators.between(Parsing_String["char"]("\""))(Parsing_Combinators.withErrorMessage(Parsing_String["char"]("\""))("end of string"))(many1(stringChar)))(function (maybeChars) {
            return pure(Data_String_CodeUnits.fromCharArray(toUnfoldable(foldr1(folder)(Data_List_Types.Nil.value)(maybeChars))));
        });
        return lexeme(Parsing_Combinators.withErrorMessage(go)("literal string"));
    })();
    var angles = function (p) {
        return Parsing_Combinators.between(symbol("<"))(symbol(">"))(p);
    };
    return {
        identifier: identifier,
        reserved: reserved,
        operator: operator,
        reservedOp: reservedOp,
        charLiteral: charLiteral,
        stringLiteral: stringLiteral,
        natural: natural,
        integer: integer,
        "float": $$float,
        naturalOrFloat: naturalOrFloat,
        decimal: decimal,
        hexadecimal: hexadecimal,
        octal: octal,
        symbol: symbol,
        lexeme: lexeme,
        whiteSpace: whiteSpace$prime(v),
        parens: parens,
        braces: braces,
        angles: angles,
        brackets: brackets,
        semi: semi,
        comma: comma,
        colon: colon,
        dot: dot,
        semiSep: semiSep,
        semiSep1: semiSep1,
        commaSep: commaSep,
        commaSep1: commaSep1
    };
};
var eof = /* #__PURE__ */ bind(Parsing.getParserT)(function (v) {
    var $147 = Data_List["null"](v.value0);
    if ($147) {
        return Parsing.consume;
    };
    return Parsing.fail("Expected EOF");
});
export {
    token,
    when,
    match,
    eof,
    LanguageDef,
    unGenLanguageDef,
    makeTokenParser
};
export {
    alphaNum,
    digit,
    hexDigit,
    letter,
    noneOf,
    octDigit,
    oneOf,
    space,
    upper
} from "../Parsing.String.Basic/index.js";
