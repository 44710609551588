// Generated by purs version 0.15.15
import * as $foreign from "./foreign.js";
import * as Web_Event_Internal_Types from "../Web.Event.Internal.Types/index.js";

export {
    eventListener,
    addEventListener,
    addEventListenerWithOptions,
    removeEventListener,
    dispatchEvent
} from "./foreign.js";
