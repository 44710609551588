// Generated by purs version 0.15.15
import * as Bound from "../Bound/index.js";
import * as Bounded from "../Bounded/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Class_Console from "../Effect.Class.Console/index.js";
import * as Eval from "../Eval/index.js";
import * as Expr from "../Expr/index.js";
import * as Halogen_Aff_Util from "../Halogen.Aff.Util/index.js";
import * as Halogen_Component from "../Halogen.Component/index.js";
import * as Halogen_HTML from "../Halogen.HTML/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_HTML_Events from "../Halogen.HTML.Events/index.js";
import * as Halogen_HTML_Properties from "../Halogen.HTML.Properties/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
import * as Halogen_VDom_Driver from "../Halogen.VDom.Driver/index.js";
import * as Token from "../Token/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var bind = /* #__PURE__ */ Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM);
var map = /* #__PURE__ */ Data_Functor.map(Halogen_Query_HalogenM.functorHalogenM);
var join = /* #__PURE__ */ Control_Bind.join(Data_Maybe.bindMaybe);
var query = /* #__PURE__ */ Halogen_Query_HalogenM.query();
var tokenizerIsSymbol = {
    reflectSymbol: function () {
        return "tokenizer";
    }
};
var query1 = /* #__PURE__ */ query(tokenizerIsSymbol)(Data_Ord.ordUnit);
var pure = /* #__PURE__ */ Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM);
var parserIsSymbol = {
    reflectSymbol: function () {
        return "parser";
    }
};
var query2 = /* #__PURE__ */ query(parserIsSymbol)(Data_Ord.ordUnit);
var boundsCheckerIsSymbol = {
    reflectSymbol: function () {
        return "boundsChecker";
    }
};
var query3 = /* #__PURE__ */ query(boundsCheckerIsSymbol)(Data_Ord.ordUnit);
var closed = /* #__PURE__ */ Bound.closed(Bounded.traversableBExpr);
var $$void = /* #__PURE__ */ Data_Functor["void"](Halogen_Query_HalogenM.functorHalogenM);
var stepperIsSymbol = {
    reflectSymbol: function () {
        return "stepper";
    }
};
var query4 = /* #__PURE__ */ query(stepperIsSymbol)(Data_Ord.ordUnit);
var slot_ = /* #__PURE__ */ Halogen_HTML.slot_();
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff);
var log = /* #__PURE__ */ Effect_Class_Console.log(Effect_Aff.monadEffectAff);
var initialValue = "(\\x.x x)\\x.x x";
var inputTA = /* #__PURE__ */ Halogen_HTML_Elements.textarea([ /* #__PURE__ */ Halogen_HTML_Properties.prop(Halogen_HTML_Core.isPropString)("value")(initialValue), /* #__PURE__ */ Halogen_HTML_Events.onValueInput(identity) ]);
var aslTokenizer = /* #__PURE__ */ (function () {
    return Type_Proxy["Proxy"].value;
})();
var aslStepper = /* #__PURE__ */ (function () {
    return Type_Proxy["Proxy"].value;
})();
var aslParser = /* #__PURE__ */ (function () {
    return Type_Proxy["Proxy"].value;
})();
var aslBoundsChecker = /* #__PURE__ */ (function () {
    return Type_Proxy["Proxy"].value;
})();
var evalApp = /* #__PURE__ */ (function () {
    var handleAction = function (str) {
        return bind(map(join)(query1(aslTokenizer)(Data_Unit.unit)(new Token.Tokenize(str, Data_Maybe.Just.create, Data_Function["const"](Data_Maybe.Nothing.value)))))(function (mToks) {
            if (mToks instanceof Data_Maybe.Nothing) {
                return pure(Data_Unit.unit);
            };
            if (mToks instanceof Data_Maybe.Just) {
                return bind(query2(aslParser)(Data_Unit.unit)(new Expr.Parse(mToks.value0, identity)))(function (mExpr) {
                    if (mExpr instanceof Data_Maybe.Nothing) {
                        return pure(Data_Unit.unit);
                    };
                    if (mExpr instanceof Data_Maybe.Just) {
                        return bind(map(join)(query3(aslBoundsChecker)(Data_Unit.unit)(new Bounded.BoundsCheck(mExpr.value0, closed))))(function (bexpr) {
                            if (bexpr instanceof Data_Maybe.Nothing) {
                                return pure(Data_Unit.unit);
                            };
                            if (bexpr instanceof Data_Maybe.Just) {
                                return $$void(query4(aslStepper)(Data_Unit.unit)(bexpr.value0));
                            };
                            throw new Error("Failed pattern match at Main (line 80, column 14 - line 82, column 75): " + [ bexpr.constructor.name ]);
                        });
                    };
                    throw new Error("Failed pattern match at Main (line 76, column 9 - line 82, column 75): " + [ mExpr.constructor.name ]);
                });
            };
            throw new Error("Failed pattern match at Main (line 72, column 5 - line 82, column 75): " + [ mToks.constructor.name ]);
        });
    };
    return Halogen_Component.mkEval({
        finalize: Data_Maybe.Nothing.value,
        receive: Data_Function["const"](Data_Maybe.Nothing.value),
        handleAction: handleAction,
        handleQuery: Data_Function["const"](pure(Data_Maybe.Nothing.value)),
        initialize: new Data_Maybe.Just(initialValue)
    });
})();
var renderApp = /* #__PURE__ */ Data_Function["const"](/* #__PURE__ */ Halogen_HTML_Elements.div([ /* #__PURE__ */ Halogen_HTML_Properties.id("app") ])([ /* #__PURE__ */ Halogen_HTML_Elements.div_([ inputTA ]), /* #__PURE__ */ slot_(tokenizerIsSymbol)(Data_Ord.ordUnit)(aslTokenizer)(Data_Unit.unit)(Token.tokenizer)(Data_Unit.unit), /* #__PURE__ */ slot_(parserIsSymbol)(Data_Ord.ordUnit)(aslParser)(Data_Unit.unit)(Expr.parserComponent)(Data_Unit.unit), /* #__PURE__ */ slot_(boundsCheckerIsSymbol)(Data_Ord.ordUnit)(aslBoundsChecker)(Data_Unit.unit)(Bounded.boundsChecker)(Data_Unit.unit), /* #__PURE__ */ slot_(stepperIsSymbol)(Data_Ord.ordUnit)(aslStepper)(Data_Unit.unit)(Eval.stepper)(Data_Unit.unit) ]));
var app = /* #__PURE__ */ Halogen_Component.mkComponent({
    initialState: /* #__PURE__ */ Data_Function["const"](""),
    render: renderApp,
    "eval": evalApp
});
var main = /* #__PURE__ */ Halogen_Aff_Util.runHalogenAff(/* #__PURE__ */ Control_Bind.bind(Effect_Aff.bindAff)(Halogen_Aff_Util.awaitBody)(function (body) {
    return discard(log("Ready to runUI"))(function () {
        return Halogen_VDom_Driver.runUI(app)(Data_Unit.unit)(body);
    });
}));
export {
    initialValue,
    inputTA,
    aslTokenizer,
    aslParser,
    aslBoundsChecker,
    aslStepper,
    renderApp,
    evalApp,
    app,
    main
};
