// Generated by purs version 0.15.15
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Newtype from "../Data.Newtype/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_HTML_Properties from "../Halogen.HTML.Properties/index.js";
import * as Matryoshka_Unfold from "../Matryoshka.Unfold/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Tuple.functorTuple);
var foldl = /* #__PURE__ */ Data_Foldable.foldl(Data_Foldable.foldableArray);
var foldl1 = /* #__PURE__ */ Data_Foldable.foldl(Data_Foldable.foldableTuple);
var foldr = /* #__PURE__ */ Data_Foldable.foldr(Data_Foldable.foldableArray);
var foldr1 = /* #__PURE__ */ Data_Foldable.foldr(Data_Foldable.foldableTuple);
var foldMap = /* #__PURE__ */ Data_Foldable.foldMap(Data_Foldable.foldableArray);
var foldMap1 = /* #__PURE__ */ Data_Foldable.foldMap(Data_Foldable.foldableTuple);
var traverse = /* #__PURE__ */ Data_Traversable.traverse(Data_Traversable.traversableArray);
var traverse1 = /* #__PURE__ */ Data_Traversable.traverse(Data_Traversable.traversableTuple);
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var unwrap = /* #__PURE__ */ Data_Newtype.unwrap();
var wrap = /* #__PURE__ */ Data_Newtype.wrap();
var un = /* #__PURE__ */ Data_Newtype.un();
var MkTreeF = function (x) {
    return x;
};
var FlowTree = function (x) {
    return x;
};
var newtypeFlowTree_ = {
    Coercible0: function () {
        return undefined;
    }
};
var functorHtmlTreeF = {
    map: function (f) {
        return function (m) {
            return {
                rootContent: m.rootContent,
                links: map(map1(f))(m.links)
            };
        };
    }
};
var foldableHtmlTreeF = {
    foldl: function (f) {
        return function (z) {
            return function (m) {
                return foldl(foldl1(f))(z)(m.links);
            };
        };
    },
    foldr: function (f) {
        return function (z) {
            return function (m) {
                return foldr(Data_Function.flip(foldr1(f)))(z)(m.links);
            };
        };
    },
    foldMap: function (dictMonoid) {
        var foldMap2 = foldMap(dictMonoid);
        var foldMap3 = foldMap1(dictMonoid);
        return function (f) {
            return function (m) {
                return foldMap2(foldMap3(f))(m.links);
            };
        };
    }
};
var traversableHtmlTreeF = {
    traverse: function (dictApplicative) {
        var map2 = Data_Functor.map((dictApplicative.Apply0()).Functor0());
        var traverse2 = traverse(dictApplicative);
        var traverse3 = traverse1(dictApplicative);
        return function (f) {
            return function (m) {
                return map2(function (v1) {
                    return {
                        rootContent: m.rootContent,
                        links: v1
                    };
                })(traverse2(traverse3(f))(m.links));
            };
        };
    },
    sequence: function (dictApplicative) {
        return function (v) {
            return Data_Traversable.traverse(traversableHtmlTreeF)(dictApplicative)(identity)(v);
        };
    },
    Functor0: function () {
        return functorHtmlTreeF;
    },
    Foldable1: function () {
        return foldableHtmlTreeF;
    }
};
var corecursiveFlowTreeHtmlTr = {
    embed: function (v) {
        var link = function (v1) {
            var linkContents = (function () {
                if (v1.value0 instanceof Data_Maybe.Nothing) {
                    return [ unwrap(v1.value1) ];
                };
                if (v1.value0 instanceof Data_Maybe.Just) {
                    return [ Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("flowtree-link-label") ])(v1.value0.value0), unwrap(v1.value1) ];
                };
                throw new Error("Failed pattern match at FlowTree (line 39, column 22 - line 42, column 85): " + [ v1.value0.constructor.name ]);
            })();
            return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("flowtree-link") ])(linkContents);
        };
        return wrap(Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("flowtree") ])([ Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("flowtree-node") ])(v.rootContent), Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("flowtree-links") ])(map(link)(v.links)) ]));
    },
    Functor0: function () {
        return functorHtmlTreeF;
    }
};
var anaM = /* #__PURE__ */ Matryoshka_Unfold.anaM(corecursiveFlowTreeHtmlTr);
var ana = /* #__PURE__ */ Matryoshka_Unfold.ana(corecursiveFlowTreeHtmlTr);
var flowTreeM = function (dictMonad) {
    var map2 = Data_Functor.map(((dictMonad.Bind1()).Apply0()).Functor0());
    var anaM1 = anaM(dictMonad)(traversableHtmlTreeF);
    return function (algm) {
        var $71 = map2(un(FlowTree));
        var $72 = anaM1(algm);
        return function ($73) {
            return $71($72($73));
        };
    };
};
var flowTree = function (alg) {
    var $74 = un(FlowTree);
    var $75 = ana(alg);
    return function ($76) {
        return $74($75($76));
    };
};
export {
    flowTree,
    flowTreeM,
    MkTreeF,
    functorHtmlTreeF,
    foldableHtmlTreeF,
    traversableHtmlTreeF
};
