// Generated by purs version 0.15.15
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Comonad_Cofree from "../Control.Comonad.Cofree/index.js";
import * as Control_Comonad_Env_Trans from "../Control.Comonad.Env.Trans/index.js";
import * as Control_Monad_Free from "../Control.Monad.Free/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Functor_Mu from "../Data.Functor.Mu/index.js";
import * as Data_Functor_Nu from "../Data.Functor.Nu/index.js";
import * as Matryoshka_Pattern_CoEnvT from "../Matryoshka.Pattern.CoEnvT/index.js";
var pure = /* #__PURE__ */ Control_Applicative.pure(Control_Monad_Free.freeApplicative);
var join = /* #__PURE__ */ Control_Bind.join(Control_Monad_Free.freeBind);
var embed = function (dict) {
    return dict.embed;
};
var corecursiveNu = function (dictFunctor) {
    return {
        embed: Data_Function.flip(Data_Functor_Nu.unfold)(Data_Functor.map(dictFunctor)(Data_Functor_Nu.observe(dictFunctor))),
        Functor0: function () {
            return dictFunctor;
        }
    };
};
var corecursiveMu = function (dictFunctor) {
    return {
        embed: Data_Functor_Mu.roll,
        Functor0: function () {
            return dictFunctor;
        }
    };
};
var corecursiveFree = function (dictFunctor) {
    var functorEnvT = Matryoshka_Pattern_CoEnvT.functorEnvT(dictFunctor);
    return {
        embed: function (v) {
            return Data_Either.either(pure)(function ($22) {
                return join(Control_Monad_Free.liftF($22));
            })(v);
        },
        Functor0: function () {
            return functorEnvT;
        }
    };
};
var corecursiveCofree = function (dictFunctor) {
    var functorEnvT = Control_Comonad_Env_Trans.functorEnvT(dictFunctor);
    return {
        embed: function (et) {
            var lower = function (v) {
                return v.value1;
            };
            var ask = function (v) {
                return v.value0;
            };
            return Control_Comonad_Cofree.mkCofree(ask(et))(lower(et));
        },
        Functor0: function () {
            return functorEnvT;
        }
    };
};
export {
    embed,
    corecursiveMu,
    corecursiveNu,
    corecursiveFree,
    corecursiveCofree
};
