// Generated by purs version 0.15.15
import * as Control_Alternative from "../Control.Alternative/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Lazy from "../Control.Lazy/index.js";
import * as Control_Monad_State_Class from "../Control.Monad.State.Class/index.js";
import * as Custom_Html from "../Custom.Html/index.js";
import * as Data_Array_NonEmpty from "../Data.Array.NonEmpty/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Newtype from "../Data.Newtype/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as FlowTree from "../FlowTree/index.js";
import * as Halogen_Component from "../Halogen.Component/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_HTML_Properties from "../Halogen.HTML.Properties/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
import * as Lexeme from "../Lexeme/index.js";
import * as Parsing from "../Parsing/index.js";
import * as Parsing_Combinators from "../Parsing.Combinators/index.js";
import * as Parsing_Token from "../Parsing.Token/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Parsing.bindParserT);
var guard = /* #__PURE__ */ Control_Alternative.guard(Parsing.alternativeParserT);
var eq = /* #__PURE__ */ Data_Eq.eq(Lexeme.eqLexeme);
var pure = /* #__PURE__ */ Control_Applicative.pure(Parsing.applicativeParserT);
var oneOf = /* #__PURE__ */ Data_Foldable.oneOf(Data_Foldable.foldableArray)(Parsing.plusParserT);
var map = /* #__PURE__ */ Data_Functor.map(Parsing.functorParserT);
var some = /* #__PURE__ */ Data_Array_NonEmpty.some(Parsing.alternativeParserT)(Parsing.lazyParserT);
var apply = /* #__PURE__ */ Control_Apply.apply(Parsing.applyParserT);
var applySecond = /* #__PURE__ */ Control_Apply.applySecond(Parsing.applyParserT);
var applyFirst = /* #__PURE__ */ Control_Apply.applyFirst(Parsing.applyParserT);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM);
var modify_ = /* #__PURE__ */ Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM);
var over = /* #__PURE__ */ Data_Newtype.over()();
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM);
var put = /* #__PURE__ */ Control_Monad_State_Class.put(Halogen_Query_HalogenM.monadStateHalogenM);
var append1 = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var pure2 = /* #__PURE__ */ Control_Applicative.pure(Control_Applicative.applicativeArray);
var show = /* #__PURE__ */ Data_Show.show(Parsing.showParseError);
var Application = /* #__PURE__ */ (function () {
    function Application(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Application.create = function (value0) {
        return function (value1) {
            return new Application(value0, value1);
        };
    };
    return Application;
})();
var Abstraction = /* #__PURE__ */ (function () {
    function Abstraction(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Abstraction.create = function (value0) {
        return function (value1) {
            return new Abstraction(value0, value1);
        };
    };
    return Abstraction;
})();
var Variable = /* #__PURE__ */ (function () {
    function Variable(value0) {
        this.value0 = value0;
    };
    Variable.create = function (value0) {
        return new Variable(value0);
    };
    return Variable;
})();
var ParserComponentState = function (x) {
    return x;
};
var Parse = /* #__PURE__ */ (function () {
    function Parse(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Parse.create = function (value0) {
        return function (value1) {
            return new Parse(value0, value1);
        };
    };
    return Parse;
})();
var showExpr = /* #__PURE__ */ (function () {
    var application = function (f) {
        return function (x) {
            return f + (" " + x);
        };
    };
    var showFinal = function (v) {
        if (v instanceof Abstraction) {
            return abstraction(v.value0)(v.value1);
        };
        return showAtom(v);
    };
    var showAtom = function (v) {
        if (v instanceof Variable) {
            return v.value0;
        };
        return "(" + (s(v) + ")");
    };
    var s = function (v) {
        if (v instanceof Abstraction) {
            return abstraction(v.value0)(v.value1);
        };
        if (v instanceof Variable) {
            return v.value0;
        };
        if (v instanceof Application) {
            return application($$function(v.value0))(showFinal(v.value1));
        };
        throw new Error("Failed pattern match at Expr (line 70, column 3 - line 70, column 40): " + [ v.constructor.name ]);
    };
    var $$function = function (v) {
        if (v instanceof Application) {
            return application($$function(v.value0))(showAtom(v.value1));
        };
        return showAtom(v);
    };
    var abstraction = function (x) {
        return function (b) {
            return "\\" + (x + ("." + s(b)));
        };
    };
    return s;
})();
var ntParserComponentState = {
    Coercible0: function () {
        return undefined;
    }
};
var nextToken = /* #__PURE__ */ Parsing_Token.token(function (v) {
    return v.end;
});
var expr = function (e) {
    var lexeme = function (l) {
        return Parsing_Combinators["try"](bind(nextToken)(function (v) {
            return guard(eq(v.lexeme)(l));
        }));
    };
    var openParen = lexeme(Lexeme.OpenParen.value);
    var lambda = lexeme(Lexeme.Lambda.value);
    var identifier = Parsing_Combinators["try"](bind(nextToken)(function (v) {
        if (v.lexeme instanceof Lexeme.Identifier) {
            return pure(v.lexeme.value0);
        };
        return Parsing.fail("Expected Identifier");
    }));
    var dot = lexeme(Lexeme.Dot.value);
    var closeParen = lexeme(Lexeme.CloseParen.value);
    var atom = oneOf([ map(Variable.create)(identifier), Parsing_Combinators.between(openParen)(closeParen)(e) ]);
    var app = bind(some(Parsing_Combinators["try"](atom)))(function (fs) {
        var i = Data_Array_NonEmpty.foldl1(Application.create)(fs);
        return bind(Parsing_Combinators.optionMaybe(Parsing_Combinators["try"](e)))(function (t) {
            return pure((function () {
                if (t instanceof Data_Maybe.Nothing) {
                    return i;
                };
                if (t instanceof Data_Maybe.Just) {
                    return new Application(i, t.value0);
                };
                throw new Error("Failed pattern match at Expr (line 44, column 12 - line 46, column 32): " + [ t.constructor.name ]);
            })());
        });
    });
    return oneOf([ apply(map(Abstraction.create)(applySecond(lambda)(identifier)))(applySecond(dot)(e)), app ]);
};
var expression = /* #__PURE__ */ Control_Lazy.fix(Parsing.lazyParserT)(expr);
var parseQuery = function (v) {
    var v1 = Parsing.runParser(v.value0)(applyFirst(expression)(Parsing_Combinators.notFollowedBy(nextToken)));
    if (v1 instanceof Data_Either.Left) {
        return discard(modify_(over(ParserComponentState)(function (v2) {
            return {
                lastExpression: v2.lastExpression,
                parseError: new Data_Maybe.Just(v1.value0)
            };
        })))(function () {
            return pure1(Data_Maybe.Nothing.value);
        });
    };
    if (v1 instanceof Data_Either.Right) {
        return discard(put({
            parseError: Data_Maybe.Nothing.value,
            lastExpression: new Data_Maybe.Just(v1.value0)
        }))(function () {
            return pure1(new Data_Maybe.Just(v.value1(v1.value0)));
        });
    };
    throw new Error("Failed pattern match at Expr (line 93, column 3 - line 99, column 31): " + [ v1.constructor.name ]);
};
var displayExpr = /* #__PURE__ */ (function () {
    var next = function (v) {
        if (v instanceof Application) {
            return [ new Data_Tuple.Tuple(Data_Maybe.Nothing.value, v.value0), new Data_Tuple.Tuple(Data_Maybe.Nothing.value, v.value1) ];
        };
        if (v instanceof Variable) {
            return [  ];
        };
        if (v instanceof Abstraction) {
            return [ new Data_Tuple.Tuple(Data_Maybe.Nothing.value, v.value1) ];
        };
        throw new Error("Failed pattern match at Expr (line 124, column 3 - line 124, column 64): " + [ v.constructor.name ]);
    };
    var step = function (ex) {
        return {
            rootContent: [ Halogen_HTML_Core.text(showExpr(ex)) ],
            links: next(ex)
        };
    };
    return FlowTree.flowTree(step);
})();
var parserComponent = /* #__PURE__ */ (function () {
    var render = function (v) {
        return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("parser") ])(append1(Data_Maybe.maybe([  ])(function (pe) {
            return pure2(Custom_Html.overlay([ "parser_error" ])([ Halogen_HTML_Core.text(show(pe)) ]));
        })(v.parseError))(Data_Maybe.maybe([  ])(function (ex) {
            return pure2(displayExpr(ex));
        })(v.lastExpression)));
    };
    return Halogen_Component.mkComponent({
        initialState: Data_Function["const"]({
            parseError: Data_Maybe.Nothing.value,
            lastExpression: Data_Maybe.Nothing.value
        }),
        render: render,
        "eval": Halogen_Component.mkEval({
            initialize: Data_Maybe.Nothing.value,
            receive: Data_Function["const"](Data_Maybe.Nothing.value),
            handleAction: Data_Function["const"](pure1(Data_Unit.unit)),
            handleQuery: parseQuery,
            finalize: Data_Maybe.Nothing.value
        })
    });
})();
export {
    Application,
    Abstraction,
    Variable,
    nextToken,
    expr,
    expression,
    showExpr,
    Parse,
    ParserComponentState,
    parseQuery,
    parserComponent,
    displayExpr,
    ntParserComponentState
};
