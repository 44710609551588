// Generated by purs version 0.15.15
import * as $foreign from "./foreign.js";

export {
    language,
    languages,
    onLine,
    platform,
    userAgent
} from "./foreign.js";
