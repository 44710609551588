// Generated by purs version 0.15.15
import * as Data_Ord from "../Data.Ord/index.js";
var compare = /* #__PURE__ */ Data_Ord.compare(Data_Ord.ordString);
var TacitString = function (x) {
    return x;
};
var showTacitString = {
    show: function (v) {
        return v;
    }
};
var hush = TacitString;
var eqTacitString = {
    eq: function (x) {
        return function (y) {
            return x === y;
        };
    }
};
var ordTacitString = {
    compare: function (x) {
        return function (y) {
            return compare(x)(y);
        };
    },
    Eq0: function () {
        return eqTacitString;
    }
};
export {
    hush,
    eqTacitString,
    ordTacitString,
    showTacitString
};
