// Generated by purs version 0.15.15
import * as Bound from "../Bound/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad from "../Control.Monad/index.js";
import * as Control_Monad_State_Class from "../Control.Monad.State.Class/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Identity from "../Data.Identity/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Newtype from "../Data.Newtype/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Expr from "../Expr/index.js";
import * as Halogen_Component from "../Halogen.Component/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_HTML_Properties from "../Halogen.HTML.Properties/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
var $runtime_lazy = function (name, moduleName, init) {
    var state = 0;
    var val;
    return function (lineNumber) {
        if (state === 2) return val;
        if (state === 1) throw new ReferenceError(name + " was needed before it finished initializing (module " + moduleName + ", line " + lineNumber + ")", moduleName, lineNumber);
        state = 1;
        val = init();
        state = 2;
        return val;
    };
};
var showRecord = /* #__PURE__ */ Data_Show.showRecord()();
var showRecordFieldsCons = /* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "argument";
    }
});
var showRecordFieldsConsNil = /* #__PURE__ */ Data_Show.showRecordFieldsConsNil({
    reflectSymbol: function () {
        return "function";
    }
});
var ApplicationIsSymbol = {
    reflectSymbol: function () {
        return "Application";
    }
};
var showRecordFieldsCons1 = /* #__PURE__ */ Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "body";
    }
})(/* #__PURE__ */ Data_Show.showRecordFieldsConsNil({
    reflectSymbol: function () {
        return "variableName";
    }
})(Data_Show.showString));
var showScope = /* #__PURE__ */ Bound.showScope(Data_Show.showUnit);
var showVar = /* #__PURE__ */ Bound.showVar(Data_Show.showUnit);
var AbstractionIsSymbol = {
    reflectSymbol: function () {
        return "Abstraction";
    }
};
var FreeVariableIsSymbol = {
    reflectSymbol: function () {
        return "FreeVariable";
    }
};
var subst = /* #__PURE__ */ Bound.subst(Bound.boundScope);
var unwrap = /* #__PURE__ */ Data_Newtype.unwrap();
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM);
var put = /* #__PURE__ */ Control_Monad_State_Class.put(Halogen_Query_HalogenM.monadStateHalogenM);
var pure = /* #__PURE__ */ Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Control_Applicative.applicativeArray);
var Application = /* #__PURE__ */ (function () {
    function Application(value0) {
        this.value0 = value0;
    };
    Application.create = function (value0) {
        return new Application(value0);
    };
    return Application;
})();
var Abstraction = /* #__PURE__ */ (function () {
    function Abstraction(value0) {
        this.value0 = value0;
    };
    Abstraction.create = function (value0) {
        return new Abstraction(value0);
    };
    return Abstraction;
})();
var FreeVariable = /* #__PURE__ */ (function () {
    function FreeVariable(value0) {
        this.value0 = value0;
    };
    FreeVariable.create = function (value0) {
        return new FreeVariable(value0);
    };
    return FreeVariable;
})();
var BoundsCheck = /* #__PURE__ */ (function () {
    function BoundsCheck(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    BoundsCheck.create = function (value0) {
        return function (value1) {
            return new BoundsCheck(value0, value1);
        };
    };
    return BoundsCheck;
})();
var genericBExpr_ = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new Application(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new Abstraction(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
            return new FreeVariable(x.value0.value0);
        };
        throw new Error("Failed pattern match at Bounded (line 26, column 1 - line 26, column 36): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Application) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof Abstraction) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof FreeVariable) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0));
        };
        throw new Error("Failed pattern match at Bounded (line 26, column 1 - line 26, column 36): " + [ x.constructor.name ]);
    }
};
var genericShow = /* #__PURE__ */ Data_Show_Generic.genericShow(genericBExpr_);
var showBExpr1 = function (dictShow) {
    var genericShowConstructor = Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(dictShow))(FreeVariableIsSymbol);
    return {
        show: function (x) {
            return genericShow(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(showRecord(showRecordFieldsCons(showRecordFieldsConsNil(showBExpr1(dictShow)))(showBExpr1(dictShow)))))(ApplicationIsSymbol))(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(showRecord(showRecordFieldsCons1(showScope(showBExpr1(showVar(showBExpr1(dictShow))))(dictShow)))))(AbstractionIsSymbol))(genericShowConstructor)))(x);
        }
    };
};
var functorBExpr = {
    map: function (f) {
        return function (m) {
            if (m instanceof Application) {
                return new Application({
                    argument: Data_Functor.map(functorBExpr)(f)(m.value0.argument),
                    "function": Data_Functor.map(functorBExpr)(f)(m["value0"]["function"])
                });
            };
            if (m instanceof Abstraction) {
                return new Abstraction({
                    variableName: m.value0.variableName,
                    body: Data_Functor.map(Bound.functorScope(functorBExpr))(f)(m.value0.body)
                });
            };
            if (m instanceof FreeVariable) {
                return new FreeVariable(f(m.value0));
            };
            throw new Error("Failed pattern match at Bounded (line 0, column 0 - line 0, column 0): " + [ m.constructor.name ]);
        };
    }
};
var foldableBExpr = {
    foldMap: function (dictMonoid) {
        var append1 = Data_Semigroup.append(dictMonoid.Semigroup0());
        return function (f) {
            var fm = function (v) {
                if (v instanceof Application) {
                    return append1(fm(v["value0"]["function"]))(fm(v.value0.argument));
                };
                if (v instanceof Abstraction) {
                    return Data_Foldable.foldMap(Bound.foldableScope(foldableBExpr))(dictMonoid)(f)(v.value0.body);
                };
                if (v instanceof FreeVariable) {
                    return f(v.value0);
                };
                throw new Error("Failed pattern match at Bounded (line 47, column 6 - line 47, column 57): " + [ v.constructor.name ]);
            };
            return fm;
        };
    },
    foldl: function (s) {
        return Data_Foldable.foldlDefault(foldableBExpr)(s);
    },
    foldr: function (s) {
        return Data_Foldable.foldrDefault(foldableBExpr)(s);
    }
};
var traversableBExpr = {
    sequence: function (dictApplicative) {
        var Apply0 = dictApplicative.Apply0();
        var apply = Control_Apply.apply(Apply0);
        var map = Data_Functor.map(Apply0.Functor0());
        return function (v) {
            if (v instanceof Application) {
                return apply(map(function (v1) {
                    return function (v2) {
                        return new Application({
                            "function": v1,
                            argument: v2
                        });
                    };
                })(Data_Traversable.sequence(traversableBExpr)(dictApplicative)(v["value0"]["function"])))(Data_Traversable.sequence(traversableBExpr)(dictApplicative)(v.value0.argument));
            };
            if (v instanceof Abstraction) {
                return map(function (v1) {
                    return new Abstraction({
                        variableName: v.value0.variableName,
                        body: v1
                    });
                })(Data_Traversable.sequence(Bound.traversableScope(traversableBExpr))(dictApplicative)(v.value0.body));
            };
            if (v instanceof FreeVariable) {
                return map(function (v1) {
                    return new FreeVariable(v1);
                })(v.value0);
            };
            throw new Error("Failed pattern match at Bounded (line 53, column 1 - line 64, column 37): " + [ v.constructor.name ]);
        };
    },
    traverse: function (dictApplicative) {
        return function (act) {
            return Data_Traversable.traverseDefault(traversableBExpr)(dictApplicative)(act);
        };
    },
    Functor0: function () {
        return functorBExpr;
    },
    Foldable1: function () {
        return foldableBExpr;
    }
};
var monadBExpr = {
    Applicative0: function () {
        return applicativeBExpr;
    },
    Bind1: function () {
        return bindBExpr;
    }
};
var bindBExpr = {
    bind: function (v) {
        return function (v1) {
            if (v instanceof Application) {
                return new Application({
                    "function": Control_Bind.bind(bindBExpr)(v["value0"]["function"])(v1),
                    argument: Control_Bind.bind(bindBExpr)(v.value0.argument)(v1)
                });
            };
            if (v instanceof Abstraction) {
                return new Abstraction({
                    variableName: v.value0.variableName,
                    body: subst(monadBExpr)(v1)(v.value0.body)
                });
            };
            if (v instanceof FreeVariable) {
                return v1(v.value0);
            };
            throw new Error("Failed pattern match at Bounded (line 35, column 1 - line 40, column 32): " + [ v.constructor.name, v1.constructor.name ]);
        };
    },
    Apply0: function () {
        return $lazy_applyBExpr(0);
    }
};
var applicativeBExpr = /* #__PURE__ */ (function () {
    return {
        pure: FreeVariable.create,
        Apply0: function () {
            return $lazy_applyBExpr(0);
        }
    };
})();
var $lazy_applyBExpr = /* #__PURE__ */ $runtime_lazy("applyBExpr", "Bounded", function () {
    return {
        apply: Control_Monad.ap(monadBExpr),
        Functor0: function () {
            return functorBExpr;
        }
    };
});
var applyBExpr = /* #__PURE__ */ $lazy_applyBExpr(29);
var fromScope = /* #__PURE__ */ Bound.fromScope(monadBExpr);
var showBExpr = function (dictMonad) {
    var pure2 = Control_Applicative.pure(dictMonad.Applicative0());
    var Bind1 = dictMonad.Bind1();
    var map = Data_Functor.map((Bind1.Apply0()).Functor0());
    var bind = Control_Bind.bind(Bind1);
    return function (showIdentifier) {
        var s = function (renderFree) {
            var s2 = function (needSelfTerminated) {
                var s3 = function (v) {
                    if (v instanceof FreeVariable) {
                        return renderFree(needSelfTerminated)(v.value0);
                    };
                    if (v instanceof Abstraction) {
                        var terminateIfNeeded = function (x) {
                            if (needSelfTerminated) {
                                return "(" + (x + ")");
                            };
                            return x;
                        };
                        var showVar1 = function (v1) {
                            if (v1 instanceof Bound.B) {
                                return Data_Function["const"](pure2(v.value0.variableName));
                            };
                            if (v1 instanceof Bound.F) {
                                return Data_Function.flip(renderFree)(v1.value0);
                            };
                            throw new Error("Failed pattern match at Bounded (line 112, column 9 - line 112, column 56): " + [ v1.constructor.name ]);
                        };
                        return map(terminateIfNeeded)(bind(s(Data_Function.flip(showVar1))(false)(fromScope(v.value0.body)))(function (shownBody) {
                            return pure2("\\" + (v.value0.variableName + ("." + shownBody)));
                        }));
                    };
                    if (v instanceof Application) {
                        return bind(s2(true)(v["value0"]["function"]))(function (shownFunction) {
                            return bind(s3(v.value0.argument))(function (shownArgument) {
                                return pure2(shownFunction + (" " + shownArgument));
                            });
                        });
                    };
                    throw new Error("Failed pattern match at Bounded (line 106, column 7 - line 106, column 60): " + [ v.constructor.name ]);
                };
                return s3;
            };
            return s2;
        };
        return s(Data_Function["const"](showIdentifier))(false);
    };
};
var displayBExpr = function (displayFree) {
    var d = function (v) {
        if (v instanceof FreeVariable) {
            return displayFree(v.value0);
        };
        if (v instanceof Abstraction) {
            var displayVar = function (v1) {
                if (v1 instanceof Bound.B) {
                    return Halogen_HTML_Elements.span([ Halogen_HTML_Properties.class_("bounded-variable") ])([ Halogen_HTML_Core.text(v.value0.variableName) ]);
                };
                if (v1 instanceof Bound.F) {
                    return d(v1.value0);
                };
                throw new Error("Failed pattern match at Bounded (line 136, column 5 - line 137, column 77): " + [ v1.constructor.name ]);
            };
            return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("bounded-abstraction") ])([ Halogen_HTML_Elements.span([ Halogen_HTML_Properties.class_("bounded-scope-label") ])([ Halogen_HTML_Core.text("Scope of " + v.value0.variableName) ]), Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("bounded-scope") ])([ displayBExpr(displayVar)(Bound.unScope(v.value0.body)) ]) ]);
        };
        if (v instanceof Application) {
            return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("bounded-application") ])([ Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("bounded-function") ])([ d(v["value0"]["function"]) ]), Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("bounded-argument") ])([ d(v.value0.argument) ]) ]);
        };
        throw new Error("Failed pattern match at Bounded (line 126, column 3 - line 126, column 37): " + [ v.constructor.name ]);
    };
    return d;
};
var boundsCheck = function (dictMonad) {
    var Bind1 = dictMonad.Bind1();
    var bind = Control_Bind.bind(Bind1);
    var pure2 = Control_Applicative.pure(dictMonad.Applicative0());
    var map = Data_Functor.map((Bind1.Apply0()).Functor0());
    return function (context) {
        var bc = function (v) {
            if (v instanceof Expr.Application) {
                return bind(bc(v.value0))(function ($$function) {
                    return bind(bc(v.value1))(function (argument) {
                        return pure2(new Application({
                            "function": $$function,
                            argument: argument
                        }));
                    });
                });
            };
            if (v instanceof Expr.Abstraction) {
                var newContext = function (v2) {
                    if (v.value0 === v2) {
                        return pure2(new Bound.B(Data_Unit.unit));
                    };
                    return map(function (y) {
                        return new Bound.F(new FreeVariable(y));
                    })(context(v2));
                };
                return bind(map(Bound.Scope)(boundsCheck(dictMonad)(newContext)(v.value1)))(function (body) {
                    return pure2(new Abstraction({
                        variableName: v.value0,
                        body: body
                    }));
                });
            };
            if (v instanceof Expr.Variable) {
                return map(FreeVariable.create)(context(v.value0));
            };
            throw new Error("Failed pattern match at Bounded (line 80, column 3 - line 83, column 46): " + [ v.constructor.name ]);
        };
        return bc;
    };
};
var boundsCheck1 = /* #__PURE__ */ boundsCheck(Data_Identity.monadIdentity);
var boundsCheckClosed = function (x) {
    return unwrap(boundsCheck1(Data_Identity.Identity)(x));
};
var boundsChecker = /* #__PURE__ */ (function () {
    var handleQuery = function (v) {
        var bound = boundsCheckClosed(v.value0);
        return discard(put(new Data_Maybe.Just(bound)))(function () {
            return pure(Data_Maybe.Just.create(v.value1(bound)));
        });
    };
    return Halogen_Component.mkComponent({
        initialState: Data_Function["const"](Data_Maybe.Nothing.value),
        render: function (mExpr) {
            return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("bounds-checker") ])(Data_Maybe.maybe([  ])((function () {
                var $149 = displayBExpr(function (unbound) {
                    return Halogen_HTML_Elements.span([ Halogen_HTML_Properties.class_("unbound-variable") ])([ Halogen_HTML_Core.text(unbound) ]);
                });
                return function ($150) {
                    return pure1($149($150));
                };
            })())(mExpr));
        },
        "eval": Halogen_Component.mkEval({
            initialize: Data_Maybe.Nothing.value,
            receive: Data_Function["const"](Data_Maybe.Nothing.value),
            handleAction: Data_Function["const"](pure(Data_Unit.unit)),
            handleQuery: handleQuery,
            finalize: Data_Maybe.Nothing.value
        })
    });
})();
export {
    Application,
    Abstraction,
    FreeVariable,
    boundsCheckClosed,
    boundsCheck,
    showBExpr,
    displayBExpr,
    BoundsCheck,
    boundsChecker,
    genericBExpr_,
    functorBExpr,
    applyBExpr,
    applicativeBExpr,
    bindBExpr,
    monadBExpr,
    foldableBExpr,
    traversableBExpr,
    showBExpr1
};
