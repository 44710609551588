// Generated by purs version 0.15.15
import * as Bound from "../Bound/index.js";
import * as Bounded from "../Bounded/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_State_Class from "../Control.Monad.State.Class/index.js";
import * as Data_Bifunctor from "../Data.Bifunctor/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Data_Void from "../Data.Void/index.js";
import * as Halogen_Component from "../Halogen.Component/index.js";
import * as Halogen_HTML from "../Halogen.HTML/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_HTML_Events from "../Halogen.HTML.Events/index.js";
import * as Halogen_HTML_Properties from "../Halogen.HTML.Properties/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var $runtime_lazy = function (name, moduleName, init) {
    var state = 0;
    var val;
    return function (lineNumber) {
        if (state === 2) return val;
        if (state === 1) throw new ReferenceError(name + " was needed before it finished initializing (module " + moduleName + ", line " + lineNumber + ")", moduleName, lineNumber);
        state = 1;
        val = init();
        state = 2;
        return val;
    };
};
var genericShowSum = /* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments)({
    reflectSymbol: function () {
        return "Beta";
    }
}));
var FunctionIsSymbol = {
    reflectSymbol: function () {
        return "Function";
    }
};
var instantiate1 = /* #__PURE__ */ Bound.instantiate1(Bounded.monadBExpr);
var map = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var bimap = /* #__PURE__ */ Data_Bifunctor.bimap(Data_Bifunctor.bifunctorTuple);
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var nextStepperIsSymbol = {
    reflectSymbol: function () {
        return "nextStepper";
    }
};
var slot_ = /* #__PURE__ */ Halogen_HTML.slot_()(nextStepperIsSymbol)(Data_Ord.ordUnit);
var mempty = /* #__PURE__ */ Data_Monoid.mempty(Data_Monoid.monoidArray);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM);
var put = /* #__PURE__ */ Control_Monad_State_Class.put(Halogen_Query_HalogenM.monadStateHalogenM);
var pure = /* #__PURE__ */ Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM);
var bind = /* #__PURE__ */ Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM);
var get = /* #__PURE__ */ Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM);
var $$void = /* #__PURE__ */ Data_Functor["void"](Halogen_Query_HalogenM.functorHalogenM);
var query = /* #__PURE__ */ Halogen_Query_HalogenM.query()(nextStepperIsSymbol)(Data_Ord.ordUnit);
var NewStart = function (x) {
    return x;
};
var Beta = /* #__PURE__ */ (function () {
    function Beta() {

    };
    Beta.value = new Beta();
    return Beta;
})();
var $$Function = /* #__PURE__ */ (function () {
    function $$Function(value0) {
        this.value0 = value0;
    };
    $$Function.create = function (value0) {
        return new $$Function(value0);
    };
    return $$Function;
})();
var genericSmallStep_ = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return Beta.value;
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return new $$Function(x.value0);
        };
        throw new Error("Failed pattern match at Eval (line 24, column 1 - line 24, column 36): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Beta) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof $$Function) {
            return new Data_Generic_Rep.Inr(x.value0);
        };
        throw new Error("Failed pattern match at Eval (line 24, column 1 - line 24, column 36): " + [ x.constructor.name ]);
    }
};
var genericShow = /* #__PURE__ */ Data_Show_Generic.genericShow(genericSmallStep_);
var showSmallStep = {
    show: function (s) {
        return genericShow(genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(showSmallStep))(FunctionIsSymbol)))(s);
    }
};
var smallStep = function (v) {
    if (v instanceof Bounded.Application) {
        if (v["value0"]["function"] instanceof Bounded.Abstraction) {
            return new Data_Maybe.Just(new Data_Tuple.Tuple(Beta.value, instantiate1(v.value0.argument)(v["value0"]["function"].value0.body)));
        };
        return map(bimap($$Function.create)(function ($65) {
            return Bounded.Application.create((function (v1) {
                return {
                    argument: v.value0.argument,
                    "function": v1
                };
            })($65));
        }))(smallStep(v["value0"]["function"]));
    };
    if (v instanceof Bounded.Abstraction) {
        return Data_Maybe.Nothing.value;
    };
    if (v instanceof Bounded.FreeVariable) {
        return Data_Maybe.Nothing.value;
    };
    throw new Error("Failed pattern match at Eval (line 32, column 1 - line 35, column 45): " + [ v.constructor.name ]);
};
var displaySmallStep = /* #__PURE__ */ Data_Show.show(showSmallStep);
var aslNextStepper = /* #__PURE__ */ (function () {
    return Type_Proxy["Proxy"].value;
})();
var $lazy_stepper = /* #__PURE__ */ $runtime_lazy("stepper", "Eval", function () {
    var render = function (v) {
        if (v instanceof Data_Maybe.Nothing) {
            return Halogen_HTML_Core.text("No expression to evaluate, yet.");
        };
        if (v instanceof Data_Maybe.Just && v.value0 instanceof Data_Maybe.Nothing) {
            return Halogen_HTML_Core.text("Expression in normal form (or stuck).");
        };
        if (v instanceof Data_Maybe.Just && v.value0 instanceof Data_Maybe.Just) {
            return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("stepper") ])(append([ Halogen_HTML_Elements.span([ Halogen_HTML_Properties.class_("stepper-prose") ])([ Halogen_HTML_Core.text("Next: "), Halogen_HTML_Elements.a([ Halogen_HTML_Events.onClick(Data_Function["const"](Data_Unit.unit)) ])([ Halogen_HTML_Core.text(displaySmallStep(v.value0.value0.value0.value0)) ]) ]) ])((function () {
                if (v.value0.value0.value1) {
                    return [ Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("stepper-expr") ])([ Bounded.displayBExpr(Data_Void.absurd)(v.value0.value0.value0.value1), slot_(aslNextStepper)(Data_Unit.unit)($lazy_stepper(82))(Data_Unit.unit) ]) ];
                };
                return mempty;
            })()));
        };
        throw new Error("Failed pattern match at Eval (line 68, column 3 - line 68, column 58): " + [ v.constructor.name ]);
    };
    var handleQuery = function (v) {
        return discard(put(Data_Maybe.Just.create((function () {
            var v1 = smallStep(v);
            if (v1 instanceof Data_Maybe.Nothing) {
                return Data_Maybe.Nothing.value;
            };
            if (v1 instanceof Data_Maybe.Just) {
                return new Data_Maybe.Just(new Data_Tuple.Tuple(v1.value0, false));
            };
            throw new Error("Failed pattern match at Eval (line 101, column 20 - line 103, column 43): " + [ v1.constructor.name ]);
        })())))(function () {
            return pure(Data_Maybe.Nothing.value);
        });
    };
    var handleAction = function (unitIn) {
        return bind(get)(function (state) {
            if (state instanceof Data_Maybe.Just && (state.value0 instanceof Data_Maybe.Just && !state.value0.value0.value1)) {
                return discard(put(new Data_Maybe.Just(new Data_Maybe.Just(new Data_Tuple.Tuple(state.value0.value0.value0, true)))))(function () {
                    return discard($$void(query(aslNextStepper)(Data_Unit.unit)(Data_Tuple.snd(state.value0.value0.value0))))(function () {
                        return pure(unitIn);
                    });
                });
            };
            return pure(unitIn);
        });
    };
    return Halogen_Component.mkComponent({
        initialState: Data_Function["const"](Data_Maybe.Nothing.value),
        render: render,
        "eval": Halogen_Component.mkEval({
            initialize: Data_Maybe.Nothing.value,
            receive: Data_Function["const"](Data_Maybe.Nothing.value),
            handleAction: handleAction,
            handleQuery: handleQuery,
            finalize: Data_Maybe.Nothing.value
        })
    });
});
var stepper = /* #__PURE__ */ $lazy_stepper(55);
export {
    Beta,
    $$Function as Function,
    displaySmallStep,
    smallStep,
    NewStart,
    aslNextStepper,
    stepper,
    genericSmallStep_,
    showSmallStep
};
