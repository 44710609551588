// Generated by purs version 0.15.15
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Monad_Gen from "../Control.Monad.Gen/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Array_NonEmpty_Internal from "../Data.Array.NonEmpty.Internal/index.js";
import * as Data_CodePoint_Unicode from "../Data.CodePoint.Unicode/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_String_CodePoints from "../Data.String.CodePoints/index.js";
import * as Data_String_CodeUnits from "../Data.String.CodeUnits/index.js";
import * as Data_String_Gen from "../Data.String.Gen/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_HTML_Properties from "../Halogen.HTML.Properties/index.js";
import * as Parsing from "../Parsing/index.js";
import * as Parsing_String from "../Parsing.String/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Parsing.functorParserT);
var voidRight = /* #__PURE__ */ Data_Functor.voidRight(Parsing.functorParserT);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var Lambda = /* #__PURE__ */ (function () {
    function Lambda() {

    };
    Lambda.value = new Lambda();
    return Lambda;
})();
var Identifier = /* #__PURE__ */ (function () {
    function Identifier(value0) {
        this.value0 = value0;
    };
    Identifier.create = function (value0) {
        return new Identifier(value0);
    };
    return Identifier;
})();
var Dot = /* #__PURE__ */ (function () {
    function Dot() {

    };
    Dot.value = new Dot();
    return Dot;
})();
var OpenParen = /* #__PURE__ */ (function () {
    function OpenParen() {

    };
    OpenParen.value = new OpenParen();
    return OpenParen;
})();
var CloseParen = /* #__PURE__ */ (function () {
    function CloseParen() {

    };
    CloseParen.value = new CloseParen();
    return CloseParen;
})();
var renderLexeme = function (v) {
    if (v instanceof Lambda) {
        return Data_String_CodeUnits.singleton("\\");
    };
    if (v instanceof Identifier) {
        return v.value0;
    };
    if (v instanceof Dot) {
        return Data_String_CodeUnits.singleton(".");
    };
    if (v instanceof OpenParen) {
        return Data_String_CodeUnits.singleton("(");
    };
    if (v instanceof CloseParen) {
        return Data_String_CodeUnits.singleton(")");
    };
    throw new Error("Failed pattern match at Lexeme (line 54, column 1 - line 54, column 33): " + [ v.constructor.name ]);
};
var identifier = /* #__PURE__ */ map(Data_String_CodeUnits.fromCharArray)(/* #__PURE__ */ Data_Array.some(Parsing.alternativeParserT)(Parsing.lazyParserT)(/* #__PURE__ */ Parsing_String.satisfy(function (c) {
    return Data_CodePoint_Unicode.isLetter(Data_String_CodePoints.codePointFromChar(c));
})));
var lexeme = /* #__PURE__ */ (function () {
    return Data_Foldable.oneOf(Data_Foldable.foldableArray)(Parsing.plusParserT)([ voidRight(Lambda.value)(Parsing_String["char"]("\\")), voidRight(Dot.value)(Parsing_String["char"](".")), voidRight(OpenParen.value)(Parsing_String["char"]("(")), voidRight(CloseParen.value)(Parsing_String["char"](")")), map(Identifier.create)(identifier) ]);
})();
var genLexeme = function (dictMonadRec) {
    var genAlphaString = Data_String_Gen.genAlphaString(dictMonadRec);
    return function (dictMonadGen) {
        var Monad0 = dictMonadGen.Monad0();
        return Control_Monad_Gen.oneOf(dictMonadGen)(Data_Array_NonEmpty_Internal.foldable1NonEmptyArray)(Data_Array.cons(Data_Functor.map(((Monad0.Bind1()).Apply0()).Functor0())(Identifier.create)(genAlphaString(dictMonadGen)))(map1(Control_Applicative.pure(Monad0.Applicative0()))([ Lambda.value, Dot.value, OpenParen.value, CloseParen.value ])));
    };
};
var eqLexeme = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Lambda && y instanceof Lambda) {
                return true;
            };
            if (x instanceof Identifier && y instanceof Identifier) {
                return x.value0 === y.value0;
            };
            if (x instanceof Dot && y instanceof Dot) {
                return true;
            };
            if (x instanceof OpenParen && y instanceof OpenParen) {
                return true;
            };
            if (x instanceof CloseParen && y instanceof CloseParen) {
                return true;
            };
            return false;
        };
    }
};
var eq1 = /* #__PURE__ */ Data_Eq.eq(eqLexeme);
var renderParse = function (x) {
    return Data_Either.either(Data_Function["const"](false))(function (v) {
        return eq1(x)(v);
    })(Parsing.runParser(renderLexeme(x))(lexeme));
};
var displayLexeme = function (lex) {
    var v = (function () {
        if (lex instanceof Lambda) {
            return new Data_Tuple.Tuple("lambda", "\\");
        };
        if (lex instanceof Identifier) {
            return new Data_Tuple.Tuple("identifier", lex.value0);
        };
        if (lex instanceof Dot) {
            return new Data_Tuple.Tuple("dot", ".");
        };
        if (lex instanceof OpenParen) {
            return new Data_Tuple.Tuple("openParen", "(");
        };
        if (lex instanceof CloseParen) {
            return new Data_Tuple.Tuple("closeParen", ")");
        };
        throw new Error("Failed pattern match at Lexeme (line 72, column 5 - line 77, column 43): " + [ lex.constructor.name ]);
    })();
    var cLexeme = function (c) {
        return "lexeme_" + c;
    };
    var cType = function (c) {
        return cLexeme("tag_" + c);
    };
    return Halogen_HTML_Elements.span([ Halogen_HTML_Properties.classes([ cLexeme("display"), cType(v.value0) ]) ])([ Halogen_HTML_Core.text(v.value1) ]);
};
var displayLexemes = function (dictFunctor) {
    var map2 = Data_Functor.map(dictFunctor);
    return function (dictFoldable) {
        var fromFoldable = Data_Array.fromFoldable(dictFoldable);
        return function (ls) {
            return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("lexemes_display") ])(fromFoldable(map2(displayLexeme)(ls)));
        };
    };
};
export {
    Lambda,
    Identifier,
    Dot,
    OpenParen,
    CloseParen,
    genLexeme,
    identifier,
    lexeme,
    renderLexeme,
    renderParse,
    displayLexeme,
    displayLexemes,
    eqLexeme
};
