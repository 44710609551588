// Generated by purs version 0.15.15
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Control_Monad from "../Control.Monad/index.js";
import * as Control_Monad_Cont_Trans from "../Control.Monad.Cont.Trans/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Control_Monad_List_Trans from "../Control.Monad.List.Trans/index.js";
import * as Control_Monad_Maybe_Trans from "../Control.Monad.Maybe.Trans/index.js";
import * as Control_Monad_RWS_Trans from "../Control.Monad.RWS.Trans/index.js";
import * as Control_Monad_Reader_Trans from "../Control.Monad.Reader.Trans/index.js";
import * as Control_Monad_State_Trans from "../Control.Monad.State.Trans/index.js";
import * as Control_Monad_Trans_Class from "../Control.Monad.Trans.Class/index.js";
import * as Control_Monad_Writer_Trans from "../Control.Monad.Writer.Trans/index.js";
import * as Data_Bifoldable from "../Data.Bifoldable/index.js";
import * as Data_Bifunctor from "../Data.Bifunctor/index.js";
import * as Data_Bitraversable from "../Data.Bitraversable/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Ordering from "../Data.Ordering/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
var $runtime_lazy = function (name, moduleName, init) {
    var state = 0;
    var val;
    return function (lineNumber) {
        if (state === 2) return val;
        if (state === 1) throw new ReferenceError(name + " was needed before it finished initializing (module " + moduleName + ", line " + lineNumber + ")", moduleName, lineNumber);
        state = 1;
        val = init();
        state = 2;
        return val;
    };
};
var BIsSymbol = {
    reflectSymbol: function () {
        return "B";
    }
};
var FIsSymbol = {
    reflectSymbol: function () {
        return "F";
    }
};
var ScopeIsSymbol = {
    reflectSymbol: function () {
        return "Scope";
    }
};
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var B = /* #__PURE__ */ (function () {
    function B(value0) {
        this.value0 = value0;
    };
    B.create = function (value0) {
        return new B(value0);
    };
    return B;
})();
var F = /* #__PURE__ */ (function () {
    function F(value0) {
        this.value0 = value0;
    };
    F.create = function (value0) {
        return new F(value0);
    };
    return F;
})();
var Scope = function (x) {
    return x;
};
var AM = function (x) {
    return x;
};
var unScope = function (v) {
    return v;
};
var substituteVar = function (dictFunctor) {
    var map1 = Data_Functor.map(dictFunctor);
    return function (dictEq) {
        var eq = Data_Eq.eq(dictEq);
        return function (v) {
            return function (s) {
                return map1(function (x) {
                    var $343 = eq(x)(v);
                    if ($343) {
                        return s;
                    };
                    return x;
                });
            };
        };
    };
};
var substitute = function (dictMonad) {
    var bind = Control_Bind.bind(dictMonad.Bind1());
    var pure1 = Control_Applicative.pure(dictMonad.Applicative0());
    return function (dictEq) {
        var eq = Data_Eq.eq(dictEq);
        return function (v) {
            return function (s) {
                return function (t) {
                    return bind(t)(function (x) {
                        var $344 = eq(x)(v);
                        if ($344) {
                            return s;
                        };
                        return pure1(x);
                    });
                };
            };
        };
    };
};
var substDefault = function (dictMonadTrans) {
    var lift = Control_Monad_Trans_Class.lift(dictMonadTrans);
    return function (dictMonad) {
        var lift1 = lift(dictMonad);
        return function (dictMonad1) {
            var bind = Control_Bind.bind(dictMonad1.Bind1());
            return function (f) {
                return function (m) {
                    return bind(m)(function ($421) {
                        return lift1(f($421));
                    });
                };
            };
        };
    };
};
var substDefault1 = /* #__PURE__ */ substDefault(Control_Monad_State_Trans.monadTransStateT);
var substDefault2 = /* #__PURE__ */ substDefault(Control_Monad_Reader_Trans.monadTransReaderT);
var substDefault3 = /* #__PURE__ */ substDefault(Control_Monad_Maybe_Trans.monadTransMaybeT);
var substDefault4 = /* #__PURE__ */ substDefault(Control_Monad_List_Trans.monadTransListT);
var substDefault5 = /* #__PURE__ */ substDefault(Control_Monad_Except_Trans.monadTransExceptT);
var substDefault6 = /* #__PURE__ */ substDefault(Control_Monad_Cont_Trans.monadTransContT);
var subst = function (dict) {
    return dict.subst;
};
var splat = function (dictMonad) {
    var bind = Control_Bind.bind(dictMonad.Bind1());
    return function (f) {
        return function (g) {
            return function (s) {
                return bind(unScope(s))(function ($$var) {
                    if ($$var instanceof B) {
                        return g($$var.value0);
                    };
                    if ($$var instanceof F) {
                        return bind($$var.value0)(f);
                    };
                    throw new Error("Failed pattern match at Bound (line 199, column 5 - line 201, column 29): " + [ $$var.constructor.name ]);
                });
            };
        };
    };
};
var semigroupAM = function (dictSemigroup) {
    var append = Data_Semigroup.append(dictSemigroup);
    return function (dictApply) {
        var lift2 = Control_Apply.lift2(dictApply);
        return {
            append: function (v) {
                return function (v1) {
                    return lift2(append)(v)(v1);
                };
            }
        };
    };
};
var monoidAM = function (dictMonoid) {
    var mempty = Data_Monoid.mempty(dictMonoid);
    var semigroupAM1 = semigroupAM(dictMonoid.Semigroup0());
    return function (dictApplicative) {
        var semigroupAM2 = semigroupAM1(dictApplicative.Apply0());
        return {
            mempty: Control_Applicative.pure(dictApplicative)(mempty),
            Semigroup0: function () {
                return semigroupAM2;
            }
        };
    };
};
var monoidAM1 = /* #__PURE__ */ monoidAM(Data_Monoid.monoidUnit);
var monadTransScope = {
    lift: function (dictMonad) {
        var pure1 = Control_Applicative.pure(dictMonad.Applicative0());
        return function (m) {
            return pure1(new F(m));
        };
    }
};
var substDefault7 = /* #__PURE__ */ substDefault(monadTransScope);
var instantiate = function (dictMonad) {
    var bind = Control_Bind.bind(dictMonad.Bind1());
    return function (f) {
        return function (scope) {
            return bind(unScope(scope))(function ($$var) {
                if ($$var instanceof B) {
                    return f($$var.value0);
                };
                if ($$var instanceof F) {
                    return $$var.value0;
                };
                throw new Error("Failed pattern match at Bound (line 190, column 5 - line 192, column 17): " + [ $$var.constructor.name ]);
            });
        };
    };
};
var instantiate1 = function (dictMonad) {
    var instantiate2 = instantiate(dictMonad);
    return function (expr) {
        return instantiate2(Data_Function["const"](expr));
    };
};
var getAM = function (v) {
    return v;
};
var genericVar = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new B(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return new F(x.value0);
        };
        throw new Error("Failed pattern match at Bound (line 89, column 1 - line 89, column 50): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof B) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof F) {
            return new Data_Generic_Rep.Inr(x.value0);
        };
        throw new Error("Failed pattern match at Bound (line 89, column 1 - line 89, column 50): " + [ x.constructor.name ]);
    }
};
var genericShow = /* #__PURE__ */ Data_Show_Generic.genericShow(genericVar);
var showVar = function (dictShow) {
    var genericShowSum = Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(dictShow))(BIsSymbol));
    return function (dictShow1) {
        return {
            show: genericShow(genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(dictShow1))(FIsSymbol)))
        };
    };
};
var genericScope = {
    to: function (x) {
        return x;
    },
    from: function (x) {
        return x;
    }
};
var genericShow1 = /* #__PURE__ */ Data_Show_Generic.genericShow(genericScope);
var showScope = function (dictShow) {
    return function (dictShow1) {
        var genericShow2 = genericShow1(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(dictShow1))(ScopeIsSymbol));
        return function (dictShow2) {
            return {
                show: genericShow2
            };
        };
    };
};
var fromScope = function (dictMonad) {
    var Bind1 = dictMonad.Bind1();
    var bind = Control_Bind.bind(Bind1);
    var map1 = Data_Functor.map((Bind1.Apply0()).Functor0());
    var pure1 = Control_Applicative.pure(dictMonad.Applicative0());
    return function (scope) {
        return bind(unScope(scope))(function ($$var) {
            if ($$var instanceof F) {
                return map1(F.create)($$var.value0);
            };
            if ($$var instanceof B) {
                return pure1(new B($$var.value0));
            };
            throw new Error("Failed pattern match at Bound (line 137, column 5 - line 139, column 26): " + [ $$var.constructor.name ]);
        });
    };
};
var foldableVar = {
    foldMap: function (dictMonoid) {
        var mempty = Data_Monoid.mempty(dictMonoid);
        return function (v) {
            return function (v1) {
                if (v1 instanceof B) {
                    return mempty;
                };
                if (v1 instanceof F) {
                    return v(v1.value0);
                };
                throw new Error("Failed pattern match at Bound (line 106, column 1 - line 110, column 29): " + [ v.constructor.name, v1.constructor.name ]);
            };
        };
    },
    foldr: function (f) {
        return Data_Foldable.foldrDefault(foldableVar)(f);
    },
    foldl: function (f) {
        return Data_Foldable.foldlDefault(foldableVar)(f);
    }
};
var foldMap = /* #__PURE__ */ Data_Foldable.foldMap(foldableVar);
var foldableScope = function (dictFoldable) {
    var foldMap1 = Data_Foldable.foldMap(dictFoldable);
    return {
        foldMap: function (dictMonoid) {
            var foldMap2 = foldMap1(dictMonoid);
            var foldMap3 = foldMap(dictMonoid);
            return function (f) {
                var $422 = foldMap2(foldMap3(foldMap2(f)));
                return function ($423) {
                    return $422(unScope($423));
                };
            };
        },
        foldr: function (f) {
            return Data_Foldable.foldrDefault(foldableScope(dictFoldable))(f);
        },
        foldl: function (f) {
            return Data_Foldable.foldlDefault(foldableScope(dictFoldable))(f);
        }
    };
};
var flipSubst = function (dictBound) {
    var subst1 = subst(dictBound);
    return function (dictMonad) {
        return Data_Function.flip(subst1(dictMonad));
    };
};
var eqVar = function (dictEq) {
    var eq = Data_Eq.eq(dictEq);
    return function (dictEq1) {
        var eq1 = Data_Eq.eq(dictEq1);
        return {
            eq: function (x) {
                return function (y) {
                    if (x instanceof B && y instanceof B) {
                        return eq(x.value0)(y.value0);
                    };
                    if (x instanceof F && y instanceof F) {
                        return eq1(x.value0)(y.value0);
                    };
                    return false;
                };
            }
        };
    };
};
var ordVar = function (dictOrd) {
    var compare = Data_Ord.compare(dictOrd);
    var eqVar1 = eqVar(dictOrd.Eq0());
    return function (dictOrd1) {
        var compare1 = Data_Ord.compare(dictOrd1);
        var eqVar2 = eqVar1(dictOrd1.Eq0());
        return {
            compare: function (x) {
                return function (y) {
                    if (x instanceof B && y instanceof B) {
                        return compare(x.value0)(y.value0);
                    };
                    if (x instanceof B) {
                        return Data_Ordering.LT.value;
                    };
                    if (y instanceof B) {
                        return Data_Ordering.GT.value;
                    };
                    if (x instanceof F && y instanceof F) {
                        return compare1(x.value0)(y.value0);
                    };
                    throw new Error("Failed pattern match at Bound (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
                };
            },
            Eq0: function () {
                return eqVar2;
            }
        };
    };
};
var eqScope = function (dictEq) {
    var eqVar1 = eqVar(dictEq);
    return function (dictEq1) {
        var eq1 = Data_Eq.eq1(dictEq1);
        return function (dictEq2) {
            var eq11 = eq1(eqVar1(dictEq2));
            return function (dictEq3) {
                return function (dictEq4) {
                    return {
                        eq: function (x) {
                            return function (y) {
                                return eq11(x)(y);
                            };
                        }
                    };
                };
            };
        };
    };
};
var ordScope = function (dictOrd) {
    var ordVar1 = ordVar(dictOrd);
    var eqScope1 = eqScope(dictOrd.Eq0());
    return function (dictOrd1) {
        var compare1 = Data_Ord.compare1(dictOrd1);
        var eqScope2 = eqScope1(dictOrd1.Eq10());
        return function (dictOrd2) {
            var compare11 = compare1(ordVar1(dictOrd2));
            var eqScope3 = eqScope2(dictOrd2.Eq0());
            return function (dictOrd3) {
                var eqScope4 = eqScope3(dictOrd3.Eq0());
                return function (dictOrd4) {
                    var eqScope5 = eqScope4(dictOrd4.Eq0());
                    return {
                        compare: function (x) {
                            return function (y) {
                                return compare11(x)(y);
                            };
                        },
                        Eq0: function () {
                            return eqScope5;
                        }
                    };
                };
            };
        };
    };
};
var closed = function (dictTraversable) {
    return Data_Traversable.traverse(dictTraversable)(Data_Maybe.applicativeMaybe)(Data_Function["const"](Data_Maybe.Nothing.value));
};
var boundWriterT = function (dictMonoid) {
    var substDefault8 = substDefault(Control_Monad_Writer_Trans.monadTransWriterT(dictMonoid));
    var monadWriterT = Control_Monad_Writer_Trans.monadWriterT(dictMonoid);
    return {
        subst: function (dictMonad) {
            return substDefault8(dictMonad)(monadWriterT(dictMonad));
        }
    };
};
var boundStateT = {
    subst: function (dictMonad) {
        return substDefault1(dictMonad)(Control_Monad_State_Trans.monadStateT(dictMonad));
    }
};
var boundReaderT = {
    subst: function (dictMonad) {
        return substDefault2(dictMonad)(Control_Monad_Reader_Trans.monadReaderT(dictMonad));
    }
};
var boundRWST = function (dictMonoid) {
    var substDefault8 = substDefault(Control_Monad_RWS_Trans.monadTransRWST(dictMonoid));
    return {
        subst: function (dictMonad) {
            return substDefault8(dictMonad)(Control_Monad_RWS_Trans.monadRWST(dictMonad)(dictMonoid));
        }
    };
};
var boundMaybeT = {
    subst: function (dictMonad) {
        return substDefault3(dictMonad)(Control_Monad_Maybe_Trans.monadMaybeT(dictMonad));
    }
};
var boundListT = {
    subst: function (dictMonad) {
        return substDefault4(dictMonad)(Control_Monad_List_Trans.monadListT(dictMonad));
    }
};
var boundExceptT = {
    subst: function (dictMonad) {
        return substDefault5(dictMonad)(Control_Monad_Except_Trans.monadExceptT(dictMonad));
    }
};
var boundContT = {
    subst: function (dictMonad) {
        return substDefault6(dictMonad)(Control_Monad_Cont_Trans.monadContT(dictMonad));
    }
};
var bindings = function (dictFoldable) {
    var f = function (v) {
        if (v instanceof B) {
            return [ v.value0 ];
        };
        return [  ];
    };
    var $424 = Data_Foldable.foldMap(dictFoldable)(Data_Monoid.monoidArray)(f);
    return function ($425) {
        return $424(unScope($425));
    };
};
var bifunctorVar = {
    bimap: function (v) {
        return function (v1) {
            return function (v2) {
                if (v2 instanceof B) {
                    return new B(v(v2.value0));
                };
                if (v2 instanceof F) {
                    return new F(v1(v2.value0));
                };
                throw new Error("Failed pattern match at Bound (line 116, column 1 - line 118, column 31): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
            };
        };
    }
};
var bimap = /* #__PURE__ */ Data_Bifunctor.bimap(bifunctorVar);
var mapScope = function (dictFunctor) {
    var map1 = Data_Functor.map(dictFunctor);
    return function (f) {
        return function (g) {
            var $426 = map1(bimap(f)(map1(g)));
            return function ($427) {
                return Scope($426(unScope($427)));
            };
        };
    };
};
var mapBound = function (dictFunctor) {
    var mapScope1 = mapScope(dictFunctor);
    return function (f) {
        return mapScope1(f)(identity);
    };
};
var bifoldableVar = {
    bifoldMap: function (dictMonoid) {
        return function (v) {
            return function (v1) {
                return function (v2) {
                    if (v2 instanceof B) {
                        return v(v2.value0);
                    };
                    if (v2 instanceof F) {
                        return v1(v2.value0);
                    };
                    throw new Error("Failed pattern match at Bound (line 119, column 1 - line 123, column 33): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
                };
            };
        };
    },
    bifoldr: function (f) {
        return Data_Bifoldable.bifoldrDefault(bifoldableVar)(f);
    },
    bifoldl: function (f) {
        return Data_Bifoldable.bifoldlDefault(bifoldableVar)(f);
    }
};
var bifoldMap = /* #__PURE__ */ Data_Bifoldable.bifoldMap(bifoldableVar);
var bitraversableVar = {
    bitraverse: function (dictApplicative) {
        var map1 = Data_Functor.map((dictApplicative.Apply0()).Functor0());
        return function (v) {
            return function (v1) {
                return function (v2) {
                    if (v2 instanceof B) {
                        return map1(B.create)(v(v2.value0));
                    };
                    if (v2 instanceof F) {
                        return map1(F.create)(v1(v2.value0));
                    };
                    throw new Error("Failed pattern match at Bound (line 124, column 1 - line 127, column 39): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
                };
            };
        };
    },
    bisequence: function (dictApplicative) {
        return function (x) {
            return Data_Bitraversable.bisequenceDefault(bitraversableVar)(dictApplicative)(x);
        };
    },
    Bifunctor0: function () {
        return bifunctorVar;
    },
    Bifoldable1: function () {
        return bifoldableVar;
    }
};
var bitraverse = /* #__PURE__ */ Data_Bitraversable.bitraverse(bitraversableVar);
var traverseScope = function (dictTraversable) {
    var traverse1 = Data_Traversable.traverse(dictTraversable);
    return function (dictApplicative) {
        var map1 = Data_Functor.map((dictApplicative.Apply0()).Functor0());
        var traverse2 = traverse1(dictApplicative);
        var bitraverse1 = bitraverse(dictApplicative);
        return function (f) {
            return function (g) {
                var $428 = map1(Scope);
                var $429 = traverse2(bitraverse1(f)(traverse2(g)));
                return function ($430) {
                    return $428($429(unScope($430)));
                };
            };
        };
    };
};
var traverseBound = function (dictTraversable) {
    var traverseScope1 = traverseScope(dictTraversable);
    return function (dictApplicative) {
        var traverseScope2 = traverseScope1(dictApplicative);
        var pure1 = Control_Applicative.pure(dictApplicative);
        return function (f) {
            return traverseScope2(f)(pure1);
        };
    };
};
var foldMapScope = function (dictFoldable) {
    var foldMap1 = Data_Foldable.foldMap(dictFoldable);
    return function (dictMonoid) {
        var foldMap2 = foldMap1(dictMonoid);
        var bifoldMap1 = bifoldMap(dictMonoid);
        return function (f) {
            return function (g) {
                var $431 = foldMap2(bifoldMap1(f)(foldMap2(g)));
                return function ($432) {
                    return $431(unScope($432));
                };
            };
        };
    };
};
var foldMapBound = function (dictFoldable) {
    var foldMapScope1 = foldMapScope(dictFoldable);
    return function (dictMonoid) {
        var foldMapScope2 = foldMapScope1(dictMonoid);
        var mempty = Data_Monoid.mempty(dictMonoid);
        return function (f) {
            return foldMapScope2(f)(Data_Function["const"](mempty));
        };
    };
};
var traverseBound_ = function (dictFoldable) {
    var foldMapBound1 = foldMapBound(dictFoldable);
    return function (dictApplicative) {
        var foldMapBound2 = foldMapBound1(monoidAM1(dictApplicative));
        var map1 = Data_Functor.map((dictApplicative.Apply0()).Functor0());
        return function (f) {
            var $433 = foldMapBound2((function () {
                var $435 = map1(Data_Function["const"](Data_Unit.unit));
                return function ($436) {
                    return AM($435(f($436)));
                };
            })());
            return function ($434) {
                return getAM($433($434));
            };
        };
    };
};
var traverseScope_ = function (dictFoldable) {
    var foldMapScope1 = foldMapScope(dictFoldable);
    return function (dictApplicative) {
        var foldMapScope2 = foldMapScope1(monoidAM1(dictApplicative));
        var map1 = Data_Functor.map((dictApplicative.Apply0()).Functor0());
        return function (f) {
            return function (g) {
                var $437 = foldMapScope2((function () {
                    var $439 = map1(Data_Function["const"](Data_Unit.unit));
                    return function ($440) {
                        return AM($439(f($440)));
                    };
                })())((function () {
                    var $441 = map1(Data_Function["const"](Data_Unit.unit));
                    return function ($442) {
                        return AM($441(g($442)));
                    };
                })());
                return function ($438) {
                    return getAM($437($438));
                };
            };
        };
    };
};
var monadVar = {
    Applicative0: function () {
        return applicativeVar;
    },
    Bind1: function () {
        return bindVar;
    }
};
var bindVar = {
    bind: function (v) {
        return function (v1) {
            if (v instanceof B) {
                return new B(v.value0);
            };
            if (v instanceof F) {
                return v1(v.value0);
            };
            throw new Error("Failed pattern match at Bound (line 101, column 1 - line 103, column 23): " + [ v.constructor.name, v1.constructor.name ]);
        };
    },
    Apply0: function () {
        return $lazy_applyVar(0);
    }
};
var applicativeVar = /* #__PURE__ */ (function () {
    return {
        pure: F.create,
        Apply0: function () {
            return $lazy_applyVar(0);
        }
    };
})();
var $lazy_functorVar = /* #__PURE__ */ $runtime_lazy("functorVar", "Bound", function () {
    return {
        map: Control_Applicative.liftA1(applicativeVar)
    };
});
var $lazy_applyVar = /* #__PURE__ */ $runtime_lazy("applyVar", "Bound", function () {
    return {
        apply: Control_Monad.ap(monadVar),
        Functor0: function () {
            return $lazy_functorVar(0);
        }
    };
});
var functorVar = /* #__PURE__ */ $lazy_functorVar(95);
var applyVar = /* #__PURE__ */ $lazy_applyVar(97);
var map = /* #__PURE__ */ Data_Functor.map(functorVar);
var pure = /* #__PURE__ */ Control_Applicative.pure(applicativeVar);
var functorScope = function (dictFunctor) {
    var map1 = Data_Functor.map(dictFunctor);
    return {
        map: function (f) {
            var $443 = map1(map(map1(f)));
            return function ($444) {
                return Scope($443(unScope($444)));
            };
        }
    };
};
var hoistScope = function (dictFunctor) {
    var map1 = Data_Functor.map(dictFunctor);
    return function (eta) {
        var $445 = map1(map(eta));
        return function ($446) {
            return Scope(eta($445(unScope($446))));
        };
    };
};
var toScope = function (dictMonad) {
    var $447 = Data_Functor.map(((dictMonad.Bind1()).Apply0()).Functor0())(map(Control_Applicative.pure(dictMonad.Applicative0())));
    return function ($448) {
        return Scope($447($448));
    };
};
var traversableVar = {
    traverse: function (dictApplicative) {
        var pure1 = Control_Applicative.pure(dictApplicative);
        var map1 = Data_Functor.map((dictApplicative.Apply0()).Functor0());
        return function (v) {
            return function (v1) {
                if (v1 instanceof B) {
                    return pure1(new B(v1.value0));
                };
                if (v1 instanceof F) {
                    return map1(F.create)(v(v1.value0));
                };
                throw new Error("Failed pattern match at Bound (line 111, column 1 - line 114, column 35): " + [ v.constructor.name, v1.constructor.name ]);
            };
        };
    },
    sequence: function (dictApplicative) {
        return function (x) {
            return Data_Traversable.sequenceDefault(traversableVar)(dictApplicative)(x);
        };
    },
    Functor0: function () {
        return functorVar;
    },
    Foldable1: function () {
        return foldableVar;
    }
};
var traverse = /* #__PURE__ */ Data_Traversable.traverse(traversableVar);
var traversableScope = function (dictTraversable) {
    var traverse1 = Data_Traversable.traverse(dictTraversable);
    var functorScope1 = functorScope(dictTraversable.Functor0());
    var foldableScope1 = foldableScope(dictTraversable.Foldable1());
    return {
        traverse: function (dictApplicative) {
            var map1 = Data_Functor.map((dictApplicative.Apply0()).Functor0());
            var traverse2 = traverse1(dictApplicative);
            var traverse3 = traverse(dictApplicative);
            return function (f) {
                var $449 = map1(Scope);
                var $450 = traverse2(traverse3(traverse2(f)));
                return function ($451) {
                    return $449($450(unScope($451)));
                };
            };
        },
        sequence: function (dictApplicative) {
            return function (s) {
                return Data_Traversable.sequenceDefault(traversableScope(dictTraversable))(dictApplicative)(s);
            };
        },
        Functor0: function () {
            return functorScope1;
        },
        Foldable1: function () {
            return foldableScope1;
        }
    };
};
var monadScope = function (dictMonad) {
    return {
        Applicative0: function () {
            return applicativeScope(dictMonad);
        },
        Bind1: function () {
            return bindScope(dictMonad);
        }
    };
};
var bindScope = function (dictMonad) {
    var bind = Control_Bind.bind(dictMonad.Bind1());
    var pure1 = Control_Applicative.pure(dictMonad.Applicative0());
    return {
        bind: function (v) {
            return function (f) {
                return bind(v)(function ($$var) {
                    if ($$var instanceof B) {
                        return pure1(new B($$var.value0));
                    };
                    if ($$var instanceof F) {
                        return bind($$var.value0)(function ($452) {
                            return unScope(f($452));
                        });
                    };
                    throw new Error("Failed pattern match at Bound (line 159, column 9 - line 161, column 44): " + [ $$var.constructor.name ]);
                });
            };
        },
        Apply0: function () {
            return applyScope(dictMonad);
        }
    };
};
var applyScope = function (dictMonad) {
    var functorScope1 = functorScope(((dictMonad.Bind1()).Apply0()).Functor0());
    return {
        apply: Control_Monad.ap(monadScope(dictMonad)),
        Functor0: function () {
            return functorScope1;
        }
    };
};
var applicativeScope = function (dictMonad) {
    var pure1 = Control_Applicative.pure(dictMonad.Applicative0());
    return {
        pure: function ($453) {
            return Scope(pure1(pure(pure1($453))));
        },
        Apply0: function () {
            return applyScope(dictMonad);
        }
    };
};
var boundScope = {
    subst: function (dictMonad) {
        return substDefault7(dictMonad)(monadScope(dictMonad));
    }
};
var $$abstract = function (dictMonad) {
    var pure1 = Control_Applicative.pure(dictMonad.Applicative0());
    var map1 = Data_Functor.map(((dictMonad.Bind1()).Apply0()).Functor0());
    return function (f) {
        var g = function ($$var) {
            var v = f($$var);
            if (v instanceof Data_Maybe.Nothing) {
                return new F(pure1($$var));
            };
            if (v instanceof Data_Maybe.Just) {
                return new B(v.value0);
            };
            throw new Error("Failed pattern match at Bound (line 180, column 19 - line 182, column 26): " + [ v.constructor.name ]);
        };
        var $454 = map1(g);
        return function ($455) {
            return Scope($454($455));
        };
    };
};
var abstract1 = function (dictEq) {
    var eq = Data_Eq.eq(dictEq);
    return function (dictMonad) {
        var abstract2 = $$abstract(dictMonad);
        return function (x) {
            return abstract2(function (y) {
                var $420 = eq(x)(y);
                if ($420) {
                    return new Data_Maybe.Just(Data_Unit.unit);
                };
                return Data_Maybe.Nothing.value;
            });
        };
    };
};
export {
    substitute,
    substituteVar,
    closed,
    subst,
    flipSubst,
    substDefault,
    B,
    F,
    Scope,
    unScope,
    fromScope,
    toScope,
    $$abstract as abstract,
    abstract1,
    instantiate,
    instantiate1,
    splat,
    bindings,
    mapBound,
    mapScope,
    foldMapBound,
    foldMapScope,
    traverseBound,
    traverseScope,
    traverseBound_,
    traverseScope_,
    hoistScope,
    boundContT,
    boundExceptT,
    boundListT,
    boundMaybeT,
    boundRWST,
    boundReaderT,
    boundStateT,
    boundWriterT,
    genericVar,
    eqVar,
    ordVar,
    showVar,
    functorVar,
    applyVar,
    applicativeVar,
    bindVar,
    monadVar,
    foldableVar,
    traversableVar,
    bifunctorVar,
    bifoldableVar,
    bitraversableVar,
    genericScope,
    eqScope,
    ordScope,
    showScope,
    functorScope,
    applyScope,
    applicativeScope,
    bindScope,
    monadScope,
    monadTransScope,
    boundScope,
    foldableScope,
    traversableScope
};
