// Generated by purs version 0.15.15
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Gen_Class from "../Control.Monad.Gen.Class/index.js";
import * as Control_Monad_State_Class from "../Control.Monad.State.Class/index.js";
import * as Custom_Html from "../Custom.Html/index.js";
import * as Custom_Parser from "../Custom.Parser/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Identity from "../Data.Identity/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Newtype from "../Data.Newtype/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Halogen_Component from "../Halogen.Component/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_HTML_Properties from "../Halogen.HTML.Properties/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
import * as Lexeme from "../Lexeme/index.js";
import * as Parsing from "../Parsing/index.js";
import * as Parsing_String from "../Parsing.String/index.js";
import * as Parsing_String_Basic from "../Parsing.String.Basic/index.js";
var apply = /* #__PURE__ */ Control_Apply.apply(Parsing.applyParserT);
var map = /* #__PURE__ */ Data_Functor.map(Parsing.functorParserT);
var applyFirst = /* #__PURE__ */ Control_Apply.applyFirst(Parsing.applyParserT);
var applySecond = /* #__PURE__ */ Control_Apply.applySecond(Parsing.applyParserT);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM);
var modify_ = /* #__PURE__ */ Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM);
var over = /* #__PURE__ */ Data_Newtype.over()();
var pure = /* #__PURE__ */ Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM);
var put = /* #__PURE__ */ Control_Monad_State_Class.put(Halogen_Query_HalogenM.monadStateHalogenM);
var displayLexemes = /* #__PURE__ */ Lexeme.displayLexemes(Data_List_Types.functorList)(Data_List_Types.foldableList);
var map1 = /* #__PURE__ */ Data_Functor.map(Data_List_Types.functorList);
var show = /* #__PURE__ */ Data_Show.show(Parsing.showParseError);
var foldr = /* #__PURE__ */ Data_Foldable.foldr(Data_List_Types.foldableList);
var unwrap = /* #__PURE__ */ Data_Newtype.unwrap();
var eq = /* #__PURE__ */ Data_Eq.eq(Lexeme.eqLexeme);
var Token = function (x) {
    return x;
};
var Tokenize = /* #__PURE__ */ (function () {
    function Tokenize(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    Tokenize.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new Tokenize(value0, value1, value2);
            };
        };
    };
    return Tokenize;
})();
var TokenizerState = function (x) {
    return x;
};
var token = function (dictMonad) {
    return function (lexeme) {
        return apply(apply(map(function (v) {
            return function (v1) {
                return function (v2) {
                    return {
                        start: v,
                        lexeme: v1,
                        end: v2
                    };
                };
            };
        })(Parsing.position))(lexeme))(Parsing.position);
    };
};
var tokens = function (dictMonad) {
    var sepByLazy = Custom_Parser.sepByLazy(dictMonad);
    var token1 = token(dictMonad);
    return function (lexeme) {
        return sepByLazy(token1(lexeme))(Parsing_String_Basic.whiteSpace);
    };
};
var tokens1 = /* #__PURE__ */ tokens(Data_Identity.monadIdentity);
var ntTokenizerState = {
    Coercible0: function () {
        return undefined;
    }
};
var tokQuery = function (v) {
    var v1 = Parsing.runParser(v.value0)(applyFirst(applyFirst(applySecond(Parsing_String_Basic.skipSpaces)(tokens1(Lexeme.lexeme)))(Parsing_String_Basic.skipSpaces))(Parsing_String.eof));
    if (v1 instanceof Data_Either.Left) {
        return discard(modify_(over(TokenizerState)(function (v2) {
            return {
                lastTokenize: v2.lastTokenize,
                parseError: new Data_Maybe.Just(v1.value0)
            };
        })))(function () {
            return pure(new Data_Maybe.Just(v.value2(v1.value0)));
        });
    };
    if (v1 instanceof Data_Either.Right) {
        return discard(put({
            parseError: Data_Maybe.Nothing.value,
            lastTokenize: v1.value0
        }))(function () {
            return pure(new Data_Maybe.Just(v.value1(v1.value0)));
        });
    };
    throw new Error("Failed pattern match at Token (line 90, column 3 - line 96, column 30): " + [ v1.constructor.name ]);
};
var tokenizer = /* #__PURE__ */ (function () {
    var render = function (v) {
        var renderToks = displayLexemes(map1(function (v1) {
            return v1.lexeme;
        })(v.lastTokenize));
        return Halogen_HTML_Elements.div([ Halogen_HTML_Properties.class_("tokenizer") ])((function () {
            if (v.parseError instanceof Data_Maybe.Nothing) {
                return [ renderToks ];
            };
            if (v.parseError instanceof Data_Maybe.Just) {
                return [ Custom_Html.overlay([ "tokenizer_error" ])([ Halogen_HTML_Core.text(show(v.parseError.value0)) ]), renderToks ];
            };
            throw new Error("Failed pattern match at Token (line 113, column 5 - line 118, column 10): " + [ v.parseError.constructor.name ]);
        })());
    };
    return Halogen_Component.mkComponent({
        initialState: Data_Function["const"]({
            parseError: Data_Maybe.Nothing.value,
            lastTokenize: Data_List_Types.Nil.value
        }),
        render: render,
        "eval": Halogen_Component.mkEval({
            initialize: Data_Maybe.Nothing.value,
            receive: Data_Function["const"](Data_Maybe.Nothing.value),
            handleAction: Data_Function["const"](pure(Data_Unit.unit)),
            handleQuery: tokQuery,
            finalize: Data_Maybe.Nothing.value
        })
    });
})();
var ntToken = {
    Coercible0: function () {
        return undefined;
    }
};
var renderTokens = function (toks) {
    var alg = function (v) {
        return function (v1) {
            return function (v2) {
                if (v instanceof Lexeme.Identifier) {
                    return (function () {
                        if (v2) {
                            return " " + v.value0;
                        };
                        return v.value0;
                    })() + v1(true);
                };
                return Lexeme.renderLexeme(v) + v1(false);
            };
        };
    };
    return foldr(alg)(Data_Function["const"](""))(map1(function ($85) {
        return (function (v) {
            return v.lexeme;
        })(unwrap($85));
    })(toks))(false);
};
var renderParseLexemes = function (toks) {
    var eqOnLexeme = function (v) {
        return function (v1) {
            if (v instanceof Data_List_Types.Nil && v1 instanceof Data_List_Types.Nil) {
                return true;
            };
            if (v instanceof Data_List_Types.Cons && v1 instanceof Data_List_Types.Cons) {
                return eq(v.value0.lexeme)(v1.value0.lexeme) && eqOnLexeme(v.value1)(v1.value1);
            };
            return false;
        };
    };
    return Data_Either.either(Data_Function["const"](false))(eqOnLexeme(toks))(Parsing.runParser(renderTokens(toks))(tokens1(Lexeme.lexeme)));
};
var genToken = function (dictMonadGen) {
    var Apply0 = ((dictMonadGen.Monad0()).Bind1()).Apply0();
    var apply1 = Control_Apply.apply(Apply0);
    var map2 = Data_Functor.map(Apply0.Functor0());
    var chooseInt = Control_Monad_Gen_Class.chooseInt(dictMonadGen);
    return function (lexeme) {
        var gp = apply1(apply1(map2(function (v) {
            return function (v1) {
                return function (v2) {
                    return {
                        column: v,
                        line: v2,
                        index: v1
                    };
                };
            };
        })(chooseInt(0)(125)))(chooseInt(1)(50000)))(chooseInt(1)(2500));
        return apply1(apply1(map2(function (v) {
            return function (v1) {
                return function (v2) {
                    return {
                        start: v,
                        end: v2,
                        lexeme: v1
                    };
                };
            };
        })(gp))(lexeme))(gp);
    };
};
export {
    Token,
    Tokenize,
    tokenizer,
    renderParseLexemes,
    genToken,
    ntToken
};
